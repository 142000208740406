import React, { createContext, useContext, useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import {
  fetchLikedProductsFromServer,
  addLikedProduct,
  removeLikedProduct,
} from "../apiServices/likedProductService";
import { useLanguage } from "./LanguageContext";
import { useAuth } from "./AuthContext";

export const LikedProductContext = createContext();

export const LikedProductProvider = ({ children }) => {
  const { loginToken } = useAuth();
  const [likedItems, setLikedItems] = useState(() => {
    const savedLikedItems = localStorage.getItem("likedItems");
    return savedLikedItems ? JSON.parse(savedLikedItems) : [];
  });
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const { language } = useLanguage();
  const [hasSynced, setHasSynced] = useState(false); // Flag to track sync status

  const sanitizeProduct = (product) => ({
    productId: product.productId,
    name:
      typeof product.name === "string"
        ? product.name.replace(/<\/?[^>]+(>|$)/g, "")
        : "",
    description:
      typeof product.description === "string"
        ? product.description.replace(/<\/?[^>]+(>|$)/g, "")
        : "",
    product: {
      name:
        typeof product.product?.name === "string"
          ? product.product.name.replace(/<\/?[^>]+(>|$)/g, "")
          : "",
      price: product.product?.price || 0,
      averageRating: product.product?.averageRating || 0,
      description:
        typeof product.product?.description === "string"
          ? product.product.description.replace(/<\/?[^>]+(>|$)/g, "")
          : "",
      image: product.product?.image || "",
      variation_options: product.product?.variation_options || [],
      in_stock: product.product?.in_stock,
    },
  });

  const fetchLikedItems = async (loginToken) => {
    setIsLoading(true);
    try {
      const data = await fetchLikedProductsFromServer(language, loginToken);
      const cleanData = data.wishList.map(sanitizeProduct);
      setLikedItems(cleanData);
      localStorage.setItem("likedItems", JSON.stringify(cleanData));
    } catch (error) {
      console.error("Error fetching liked items:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const syncLikedItemsToDatabase = async () => {
    if (!loginToken || hasSynced) return; // Only sync once

    const storedLikedItems = JSON.parse(
      localStorage.getItem("likedItems") || "[]"
    );

    try {
      if (storedLikedItems.length > 0) {
        for (const item of storedLikedItems) {
          await addLikedProduct(item.productId, loginToken);
        }
        localStorage.removeItem("likedItems");
      }
      await fetchLikedItems(loginToken);
      setHasSynced(true); // Mark as synced
    } catch (err) {
      console.error("Error syncing liked items:", err);
    }
  };

  const addLikeProduct = async (product) => {
    const sanitizedProduct = sanitizeProduct(product);

    const existingItem = likedItems.some(
      (item) => item.productId === sanitizedProduct.productId
    );
    if (existingItem) {
      enqueueSnackbar("Product is already in your favorites!", {
        variant: "info",
      });
      return;
    }

    const updatedLikedItems = [...likedItems, sanitizedProduct];
    setLikedItems(updatedLikedItems);
    localStorage.setItem("likedItems", JSON.stringify(updatedLikedItems));

    if (loginToken) {
      try {
        await addLikedProduct(sanitizedProduct.productId, loginToken);
        enqueueSnackbar("Product added to favorites!", {
          variant: "success",
        });
      } catch (err) {
        console.error("Error adding product to favorites:", err);
      }
    }
  };

  const removeFromFavorite = async (product) => {
    const updatedLikedItems = likedItems.filter(
      (item) => item.productId !== product.productId
    );
    setLikedItems(updatedLikedItems);
    localStorage.setItem("likedItems", JSON.stringify(updatedLikedItems));
    enqueueSnackbar("Product removed from favorites!", { variant: "warning" });

    if (loginToken) {
      try {
        await removeLikedProduct(product.productId, loginToken);
      } catch (err) {
        console.error("Error removing product from favorites:", err);
      }
    }
  };

  const clearLikedItems = () => {
    setLikedItems([]);
    localStorage.removeItem("likedItems");
  };

  useEffect(() => {
    if (loginToken) {
      syncLikedItemsToDatabase();
    } else {
      clearLikedItems();
    }
  }, [loginToken]);

  useEffect(() => {
    const cleanLikedItems = likedItems.map(sanitizeProduct);
    localStorage.setItem("likedItems", JSON.stringify(cleanLikedItems));
  }, [likedItems]);

  return (
    <LikedProductContext.Provider
      value={{
        likedItems,
        fetchLikedItems,
        addLikeProduct,
        removeFromFavorite,
        clearLikedItems,
        isLoading,
      }}
    >
      {children}
    </LikedProductContext.Provider>
  );
};
