import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useSnackbar } from "notistack";
import { ClipLoader } from "react-spinners";

const ForgotPasswordModel = ({ show, handleClose }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  // Handle Email Submission
  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      // Send email to backend for password reset link
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/forgot-password`,
        { email }
      );
      if (response.status === 200) {
        setSuccess(true);
        enqueueSnackbar("🔑 Password reset link has been sent to your email!", {
          variant: "success",
        });
      } else {
        enqueueSnackbar("Failed to send reset link. Try again.", {
          variant: "error",
        });
      }
    } catch (error) {
      enqueueSnackbar("An error occurred. Please try again.", {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  if (!show) return null;

  return (
    <>
      {/* Background Overlay */}
      <div className="overlay-custom" onClick={handleClose}></div>
      <div
        className="modal fade show"
        tabIndex="-1"
        style={{ display: "block" }}
      >
        <div className="modal-dialog p-3 modal-dialog-centered modal-lg justify-content-center">
          <div className="modal-content p-0 border-radius-none">
            <div className="modal-header px-md-4">
              <h5 className="modal-title">{t("Forgot Password")}</h5>
              <button
                type="button"
                className="btn-close"
                onClick={handleClose}
              ></button>
            </div>
            <div className="modal-body px-md-4 col-md-12">
              {!success ? (
                <form onSubmit={handleEmailSubmit}>
                  <div className="form-group">
                    <label htmlFor="email">{t("Enter your email")}</label>
                    <input
                      type="email"
                      id="email"
                      placeholder={t("Enter your email")}
                      className="form-control"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group mt-3 col-9 col-md-4">
                    <button
                      type="submit"
                      className="btn btn-green-theme"
                      disabled={loading}
                    >
                      {loading ? (
                        <ClipLoader
                          color={"#000"}
                          loading={loading}
                          size={24}
                        />
                      ) : (
                        t("Send Reset Link")
                      )}
                    </button>
                  </div>
                </form>
              ) : (
                <div className="text-center">
                  <h5>{t("Check your email")}</h5>
                  <p>
                    {t("A password reset link has been sent to your email.")}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPasswordModel;
