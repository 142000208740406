import React, { createContext, useState, useEffect } from "react";
import {
  deleteAddressOnServer,
  fetchAddressData,
  addAddress,
  updateAddress,
  fetchCountryCity,
} from "../apiServices/addressServices";
import { useAuth } from "./AuthContext";

export const AddressContext = createContext();

export const AddressProvider = ({ children }) => {
  const [addresses, setAddresses] = useState([]);
  const [selectedAddressId, setSelectedAddressId] = useState(null);
  const [paymentGateways, setPaymentGateways] = useState([]);
  const [shippingMethods, setShippingMethods] = useState([]);
  const [countries, setCountries] = useState([]);
  const { loginToken } = useAuth();

  // Fetch addresses and other data, updating state
  const fetchAddresses = async (loginToken) => {
    if (loginToken) {
      try {
        const response = await fetchAddressData(loginToken);

        if (Array.isArray(response.addresses)) {
          setAddresses(response.addresses);
        } else {
          console.error(
            "Expected an array of addresses but got:",
            response.addresses
          );
        }

        if (Array.isArray(response.paymentGateways)) {
          setPaymentGateways(response.paymentGateways);
        } else {
          console.error(
            "Expected an array of payment gateways but got:",
            response.paymentGateways
          );
        }

        if (Array.isArray(response.shippingMethods)) {
          setShippingMethods(response.shippingMethods);
        } else {
          console.error(
            "Expected an array of shipping methods but got:",
            response.shippingMethods
          );
        }
      } catch (error) {
        console.error("Error fetching addresses:", error);
      }
    }
  };

  // Fetch country and city data
  const fetchCountries = async () => {
    try {
      const countryData = await fetchCountryCity();
      setCountries(countryData);
    } catch (error) {
      console.error("Error fetching country and city data:", error);
    }
  };

  // Add a new address and ensure only one default address
  const handleAddAddress = async (addressData) => {
    if (loginToken) {
      try {
        // If the new address is marked as default, update existing addresses to remove default setting
        if (addressData.defaultaddress) {
          const updatedAddresses = await Promise.all(
            addresses.map((address) =>
              address.defaultaddress
                ? updateAddress(loginToken, address.id, {
                    ...address,
                    defaultaddress: 0, // Remove default from the old address
                  })
                : address
            )
          );
          // Update local state to reflect removed default tags
          setAddresses(updatedAddresses);
        }

        // Add the new address (either default or not)
        const newAddress = await addAddress(loginToken, addressData);
        setAddresses((prevAddresses) => [...prevAddresses, newAddress]);

        // Refetch addresses to ensure correct data
        await fetchAddresses();
      } catch (error) {
        console.error("Error adding address:", error);
      }
    }
  };

  // Update an existing address and update the state
  const handleUpdateAddress = async (addressId, addressData) => {
    if (loginToken) {
      try {
        // If the new address is marked as default, remove the default tag from existing addresses
        if (addressData.defaultaddress) {
          const updatedAddresses = await Promise.all(
            addresses.map((address) =>
              address.defaultaddress
                ? updateAddress(loginToken, address.id, {
                    ...address,
                    defaultaddress: 0, // Remove default from the old address
                  })
                : address
            )
          );
          // Update local state to reflect removed default tags
          setAddresses(updatedAddresses);
        }

        // Update the address
        const updatedAddress = await updateAddress(
          loginToken,
          addressId,
          addressData
        );

        // Update the state with the updated address
        setAddresses((prevAddresses) =>
          prevAddresses.map((address) =>
            address.id === addressId ? updatedAddress : address
          )
        );
      } catch (error) {
        console.error("Error updating address:", error);
      }
    }
  };

  // Delete an address and update the state
  const handleDeleteAddress = async (addressId) => {
    if (loginToken) {
      try {
        await deleteAddressOnServer(loginToken, addressId);
        setAddresses((prevAddresses) =>
          prevAddresses.filter((address) => address.id !== addressId)
        );
      } catch (error) {
        console.error("Error deleting address:", error);
      }
    }
  };

  const value = {
    addresses,
    selectedAddressId,
    setSelectedAddressId,
    handleDeleteAddress,
    handleAddAddress,
    handleUpdateAddress,
    fetchAddresses,
    paymentGateways,
    shippingMethods,
    fetchCountries,
    countries,
  };

  return (
    <AddressContext.Provider value={value}>{children}</AddressContext.Provider>
  );
};
