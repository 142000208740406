import React, { createContext, useState, useEffect, useContext } from "react";
import { fetchSettings } from "../apiServices/settingsServices";

const SettingsContext = createContext();

export const SettingsProvider = ({ children }) => {
  const [shippingFreeAmount, setShippingFreeAmount] = useState(null);
  const [logo, setLogo] = useState(null);
  const [title, setTitle] = useState("");
  const [seo, setSeo] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loadSettings = async () => {
      setLoading(true);
      try {
        const options = await fetchSettings(); // Use the service function
        console.log(options.shipping_free_amount);
        setShippingFreeAmount(options.shipping_free_amount || 0);
        setLogo(options.logo || null);
        setTitle(options.title || "");
        setSeo(options.seo || {});
      } catch (error) {
        console.error("Failed to load settings:", error);
      } finally {
        setLoading(false);
      }
    };

    loadSettings();
  }, []);

  return (
    <SettingsContext.Provider
      value={{
        shippingFreeAmount,
        logo,
        title,
        seo,
        loading,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

// Custom hook to use SettingsContext
export const useSettings = () => {
  return useContext(SettingsContext);
};
