export const menuData = [
  {
    category: "medicine",
    links: [
      {
        name: "All Medicines",
        subLinks: [
          {
            name: "Ointments",
            subLinks: [
              {
                name: "Pain Relief",
                subLinks: [
                  { name: "Aspirin", subLinks: [] },
                  { name: "Ibuprofen", subLinks: [] },
                  { name: "Paracetamol", subLinks: [] },
                ],
              },
              {
                name: "Antiseptics",
                subLinks: [
                  { name: "Dettol", subLinks: [] },
                  { name: "Savlon", subLinks: [] },
                ],
              },
            ],
          },
          {
            name: "Tablets",
            subLinks: [
              {
                name: "Antibiotics",
                subLinks: [
                  { name: "Penicillin", subLinks: [] },
                  { name: "Amoxicillin", subLinks: [] },
                ],
              },
              {
                name: "Painkillers",
                subLinks: [
                  { name: "Paracetamol", subLinks: [] },
                  { name: "Ibuprofen", subLinks: [] },
                ],
              },
            ],
          },
          {
            name: "Syrups",
            subLinks: [
              {
                name: "Cough Syrups",
                subLinks: [
                  { name: "Benylin", subLinks: [] },
                  { name: "Robitussin", subLinks: [] },
                ],
              },
              {
                name: "Multivitamins",
                subLinks: [
                  { name: "Cenovit", subLinks: [] },
                  { name: "Revicon", subLinks: [] },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "Vitamins",
        subLinks: [
          {
            name: "Ointments",
            subLinks: [
              {
                name: "Vitamin E",
                subLinks: [
                  { name: "Bio-Oil", subLinks: [] },
                  { name: "Himalaya", subLinks: [] },
                ],
              },
            ],
          },
          {
            name: "Tablets",
            subLinks: [
              {
                name: "Vitamin C",
                subLinks: [
                  { name: "Cenovit", subLinks: [] },
                  { name: "Revicon", subLinks: [] },
                ],
              },
              {
                name: "Vitamin D",
                subLinks: [
                  { name: "D3", subLinks: [] },
                  { name: "Calcimax", subLinks: [] },
                ],
              },
            ],
          },
          {
            name: "Syrups",
            subLinks: [
              {
                name: "Multivitamins",
                subLinks: [
                  { name: "Revicon", subLinks: [] },
                  { name: "Enervon", subLinks: [] },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "Pain Relief",
        subLinks: [
          {
            name: "Ointments",
            subLinks: [
              {
                name: "Painkillers",
                subLinks: [
                  { name: "Aspirin", subLinks: [] },
                  { name: "Ibuprofen", subLinks: [] },
                ],
              },
            ],
          },
          {
            name: "Tablets",
            subLinks: [
              {
                name: "Antibiotics",
                subLinks: [
                  { name: "Penicillin", subLinks: [] },
                  { name: "Amoxicillin", subLinks: [] },
                ],
              },
            ],
          },
          {
            name: "Syrups",
            subLinks: [
              {
                name: "Pain Relief Syrups",
                subLinks: [
                  { name: "Dolo", subLinks: [] },
                  { name: "Paracetamol Syrup", subLinks: [] },
                ],
              },
            ],
          },
        ],
      },
    ],
    products: [
      {
        id: 1,
        name: "Penicillin",
        image:
          "https://media.istockphoto.com/id/1277009188/photo/close-up-of-hand-holding-blister-packs.webp?a=1&b=1&s=612x612&w=0&k=20&c=TknwM7JDZCu_LLoq1FvxnxUwyuXKcav9irMra7zpi6U=",
      },
      {
        id: 2,
        name: "Insulin",
        image:
          "https://images.unsplash.com/photo-1612851300081-30cac0077b3b?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8aW5zdWxpbnxlbnwwfHwwfHx8MA%3D%3D",
      },
      {
        id: 3,
        name: "Paracetamol",
        image:
          "https://media.istockphoto.com/id/1022216070/photo/packet-of-generic-paracetamol-tablets.webp?a=1&b=1&s=612x612&w=0&k=20&c=UFjLzB41Y_dst-ZRd_xnwMYS4EKaVP3dd-bcZUmOO-s=",
      },
    ],
  },
  {
    category: "dryFruits",
    links: [
      {
        name: "Almonds",
        subLinks: [
          {
            name: "Roasted Almonds",
            subLinks: [],
          },
          {
            name: "Raw Almonds",
            subLinks: [],
          },
          {
            name: "Almond Butter",
            subLinks: [],
          },
        ],
      },
      {
        name: "Cashews",
        subLinks: [
          {
            name: "Salted Cashews",
            subLinks: [],
          },
          {
            name: "Raw Cashews",
            subLinks: [],
          },
          {
            name: "Cashew Butter",
            subLinks: [],
          },
        ],
      },
      {
        name: "Dates",
        subLinks: [
          {
            name: "Medjool Dates",
            subLinks: [],
          },
          {
            name: "Deglet Noor Dates",
            subLinks: [],
          },
          {
            name: "Date Syrup",
            subLinks: [],
          },
        ],
      },
    ],
    products: [
      {
        id: 1,
        name: "Roasted Almonds",
        image:
          "https://images.unsplash.com/photo-1579282940319-c483172dd0f2?q=80&w=1470&auto=format&fit=crop",
      },
      {
        id: 2,
        name: "Salted Cashews",
        image:
          "https://images.unsplash.com/photo-1509912760195-4f6cfd8cce2c?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      },
      {
        id: 3,
        name: "Medjool Dates",
        image:
          "https://images.unsplash.com/photo-1633677658580-2535af0cfb00?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      },
      {
        id: 4,
        name: "Almond Butter",
        image:
          "https://images.unsplash.com/photo-1615110250484-e8c3b151b957?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8QWxtb25kJTIwQnV0dGVyfGVufDB8fDB8fHww",
      },
      {
        id: 5,
        name: "Raw Cashews",
        image:
          "https://images.unsplash.com/photo-1627820752174-acae1b399128?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8UmF3JTIwQ2FzaGV3c3xlbnwwfHwwfHx8MA%3D%3D",
      },
    ],
  },
  {
    category: "healthInstruments",
    links: [
      {
        name: "Blood Pressure Monitors",
        subLinks: [
          {
            name: "Automatic Monitors",
            subLinks: [],
          },
          {
            name: "Manual Monitors",
            subLinks: [],
          },
          {
            name: "Digital Monitors",
            subLinks: [],
          },
        ],
      },
      {
        name: "Thermometers",
        subLinks: [
          {
            name: "Digital Thermometers",
            subLinks: [],
          },
          {
            name: "Infrared Thermometers",
            subLinks: [],
          },
          {
            name: "Mercury Thermometers",
            subLinks: [],
          },
        ],
      },
      {
        name: "Oximeters",
        subLinks: [
          {
            name: "Finger Pulse Oximeters",
            subLinks: [],
          },
          {
            name: "Handheld Oximeters",
            subLinks: [],
          },
          {
            name: "Wrist Oximeters",
            subLinks: [],
          },
        ],
      },
    ],
    products: [
      {
        id: 1,
        name: "Radiology Machine",
        image:
          "https://plus.unsplash.com/premium_photo-1702599055521-d7b3d6014d36?q=80&w=1376&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      },
      {
        id: 2,
        name: "Stethoscopes",
        image:
          "https://images.unsplash.com/photo-1584467735871-8e85353a8413?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTJ8fHN0ZXRob3Njb3Blc3xlbnwwfHwwfHx8MA%3D%3D",
      },
      {
        id: 3,
        name: "BP Monitors",
        image:
          "https://plus.unsplash.com/premium_photo-1675624949127-9add08a24bc0?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8Ymxvb2QlMjBwcmVzc3VyZSUyMG1vbml0b3JzfGVufDB8fDB8fHww",
      },
      {
        id: 4,
        name: "IR Thermometer",
        image:
          "https://images.unsplash.com/photo-1607583449928-4ab0cb473e6a?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      },
      {
        id: 5,
        name: "Oximeter",
        image:
          "https://images.unsplash.com/photo-1530508943348-b8f606ea2bf2?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      },
      {
        id: 6,
        name: "Mercury Thermometer",
        image:
          "https://media.istockphoto.com/id/1211644246/photo/medical-thermometer-on-white-background.webp?a=1&b=1&s=612x612&w=0&k=20&c=ooyC9tr3UJ_nklEDJJvoyaSKyjiTut8emimbV2ieqAk=",
      },
    ],
  },
];
