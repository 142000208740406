import { API_ENDPOINTS } from "../ApiEndPoints";

const contactUsUrl = `${process.env.REACT_APP_API_URL}${API_ENDPOINTS.CONTACT_US}`;

export const sendContactForm = async (formData) => {
  try {
    const response = await fetch(contactUsUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      throw new Error('Failed to send message');
    }

    return await response.json(); // Return success data
  } catch (error) {
    throw new Error(error.message); // Handle error
  }
};
