import React, { createContext, useState, useCallback } from "react";
import {
  fetchReviews,
  submitReview,
  updateReview,
} from "../apiServices/reviewServices";

const CustomerReviewContext = createContext();

export const CustomerReviewProvider = ({ children }) => {
  const [userId, setUserId] = useState(null); // Store the logged-in user's ID
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [averageRating, setAverageRating] = useState(0);
  const [ratingBreakdown, setRatingBreakdown] = useState({});
  const [totalReviews, setTotalReviews] = useState(0);

  // Fetch reviews (no change from previous version)
  const getReviews = useCallback(
    async (productId) => {
      setLoading(true);
      setError(null);
      try {
        const data = await fetchReviews(productId);
        const reviewsData = data.data;

        // Store reviews data
        setReviews(reviewsData);

        // Calculate average rating
        const total = reviewsData.reduce(
          (acc, review) => acc + review.rating,
          0
        );
        const average = (total / reviewsData.length).toFixed(2);
        setAverageRating(average);

        // Calculate rating breakdown (1-5 stars)
        const breakdown = reviewsData.reduce((acc, review) => {
          acc[review.rating] = (acc[review.rating] || 0) + 1;
          return acc;
        }, {});
        setRatingBreakdown(breakdown);

        // Total number of reviews
        setTotalReviews(reviewsData.length);

        // Extract and set the logged-in user's review (if available)
        const loggedInUserReview = reviewsData.find(
          (review) => review.user.id === userId
        );
        if (loggedInUserReview) {
          console.log("User's review found:", loggedInUserReview);
        }
      } catch (err) {
        setError("Failed to load reviews. Please try again.");
      } finally {
        setLoading(false);
      }
    },
    [userId]
  );

  // Submit a new review
  const submitCustomerReview = async (
    productId,
    orderId,
    comment,
    rating,
    loginToken
  ) => {
    setLoading(true);
    setError(null);
    try {
      const data = await submitReview(
        productId,
        orderId,
        comment,
        rating,
        loginToken
      );
      console.log("Review submitted successfully:", data);
      // After successful submission, refetch the reviews
      await getReviews(productId);
    } catch (err) {
      setError("Failed to submit review. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Update an existing review
  const updateCustomerReview = async (
    reviewId,
    productId,
    comment,
    rating,
    loginToken
  ) => {
    setLoading(true);
    setError(null);
    try {
      const data = await updateReview(
        reviewId,
        productId,
        comment,
        rating,
        loginToken
      ); // API call to update review
      console.log("Review updated successfully:", data);
      // After successful update, refetch the reviews
      await getReviews(productId);
    } catch (err) {
      setError("Failed to update review. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <CustomerReviewContext.Provider
      value={{
        reviews,
        loading,
        error,
        averageRating,
        ratingBreakdown,
        totalReviews,
        getReviews,
        submitCustomerReview, // Expose submit function
        updateCustomerReview, // Expose update function
        userId, // Expose userId
        setUserId, // Allow setting the logged-in user's ID
      }}
    >
      {children}
    </CustomerReviewContext.Provider>
  );
};

export default CustomerReviewContext;
