import { API_ENDPOINTS } from "../ApiEndPoints";

const pagesUrl = `${process.env.REACT_APP_API_URL}${API_ENDPOINTS.PAGES}`;

/**
 * Fetch Terms and Conditions
 * @returns {Promise<Object>} - Data for terms and conditions
 */
const getTermsAndConditions = async () => {
  try {
    const response = await fetch(`${pagesUrl}/terms-conditions`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Failed to fetch terms and conditions");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching terms and conditions:", error);
    throw error;
  }
};

/**
 * Fetch Refund Policies
 * @returns {Promise<Object>} - Data for refund policies
 */
const getRefundPolicies = async () => {
  try {
    const response = await fetch(`${pagesUrl}/refund-policies`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Failed to fetch refund policies");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching refund policies:", error);
    throw error;
  }
};

/**
 * Fetch Return Policies
 * @returns {Promise<Object>} - Data for return policies
 */
const getReturnPolicies = async () => {
  try {
    const response = await fetch(`${pagesUrl}/return-policies`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Failed to fetch return policies");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching return policies:", error);
    throw error;
  }
};

// Export all the API functions
export { getTermsAndConditions, getRefundPolicies, getReturnPolicies };
