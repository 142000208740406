import React from "react";
import { useNavigate } from "react-router-dom";
import "./NotFound.css";

const NotFound = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate("/");
  };

  return (
    <div className="notfound-container">
      <div className="notfound-animation">
        <svg xmlns="http://www.w3.org/2000/svg" className="notfound-svg">
          <text
            x="50%"
            y="70%"
            textAnchor="middle"
            className="notfound-text"
            fill="none"
            stroke="#677425"
            strokeWidth="4"
          >
            404
          </text>
        </svg>
      </div>
      <h1 className="notfound-title">Oops! Page Not Found</h1>
      <p className="notfound-description">
        Sorry, the page you were looking for doesn’t exist or has been moved.
      </p>
      <button className="notfound-button" onClick={handleGoHome}>
        Return to Home
      </button>
    </div>
  );
};

export default NotFound;
