import React, { createContext, useState, useEffect } from "react";
import {
  fetchUserData,
  updateProfile,
  uploadAttachment,
} from "../apiServices/userDataService";
import { useAuth } from "./AuthContext";

const UserProfileContext = createContext();

export const UserProfileProvider = ({ children }) => {
  const { loginToken, isLoggedIn } = useAuth(); // Get loginToken and isLoggedIn from AuthContext
  const [userProfile, setUserProfile] = useState({
    profileImage: null,
    profileImageUrl: "",
    firstname: "",
    lastname: "",
    email: "",
    contact: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Function to load user data
  const loadUserData = async () => {
    setLoading(true);
    try {
      const userData = await fetchUserData(loginToken); // Pass loginToken to the API
      setUserProfile({
        profileImage: null,
        profileImageUrl: userData.avatar.original,
        firstname: userData.firstname,
        lastname: userData.lastname,
        email: userData.email,
        contact: userData.contact,
      });
    } catch (error) {
      console.error("Error fetching user data:", error.message);
      setError("Failed to fetch user data.");
    } finally {
      setLoading(false);
    }
  };

  // Function to handle profile updates
  const handleUpdateProfile = async (updatedData, loginToken) => {
    setLoading(true);
    setError(null);

    try {
      let newProfileImageUrl = userProfile.profileImageUrl;

      if (updatedData.profileImage) {
        const imageData = await uploadAttachment(
          updatedData.profileImage,
          loginToken
        );
        newProfileImageUrl = imageData.original;
      }

      const response = await updateProfile(updatedData, loginToken); // Pass loginToken to the API

      if (response.success) {
        setUserProfile((prevState) => ({
          ...prevState,
          profileImageUrl: newProfileImageUrl,
          firstname: response.customer.firstname,
          lastname: response.customer.lastname,
          email: response.customer.email,
          contact: response.customer.contact,
        }));
      } else {
        setError("Profile update failed. Please try again.");
      }
    } catch (error) {
      setError("Error updating profile. Please check your input.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <UserProfileContext.Provider
      value={{
        userProfile,
        loading,
        error,
        loadUserData,
        handleUpdateProfile,
      }}
    >
      {children}
    </UserProfileContext.Provider>
  );
};

export default UserProfileContext;
