import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSettings } from "../src/Context/SettingsContext";
import defaultLogo from "./img/Logo-image-removebg-preview.png";

const SiteLogo = ({ handleCloseDrawer, className }) => {
  const { logo, title, seo } = useSettings();

  useEffect(() => {
    if (title) {
      document.title = title;

      const metaDescription = document.querySelector(
        'meta[name="description"]'
      );
      if (metaDescription && seo?.metaTitle) {
        metaDescription.setAttribute("content", seo.metaTitle);
      }
    }
  }, [title, seo]);

  return (
    <div className={`${className} logo-size`}>
      <Link to="/">
        {logo?.thumbnail ? (
          <img
            className="w-100"
            src={logo.thumbnail}
            alt="Site Logo"
            onClick={() => handleCloseDrawer()}
          />
        ) : (
          <img
            className="w-100"
            src={defaultLogo}
            alt="Default Logo"
            onClick={() => handleCloseDrawer()}
          />
        )}
      </Link>
    </div>
  );
};

export default SiteLogo;
