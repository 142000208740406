import React, { createContext, useState, useContext } from "react";
import {
  verifyCurrentPassword,
  verifyOtp,
  changePassword,
} from "../apiServices/ChangePasswordService";
import { useAuth } from "./AuthContext";

const ChangePasswordContext = createContext();

export const ChangePasswordProvider = ({ children }) => {
  const { loginToken } = useAuth();
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    currentPassword: "",
    otp: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleVerifyPassword = async () => {
    setLoading(true);
    setError(null);
    try {
      await verifyCurrentPassword(formData.currentPassword, loginToken);
      setStep(2); // Proceed to OTP verification
    } catch (err) {
      setError("Failed to verify the current password.");
    } finally {
      setLoading(false);
    }
  };

  const handleVerifyOtp = async () => {
    setLoading(true);
    setError(null);
    try {
      await verifyOtp(formData.otp, loginToken);
      setStep(3); // Proceed to setting the new password
    } catch (err) {
      setError("Failed to verify OTP.");
    } finally {
      setLoading(false);
    }
  };

  const handleChangePassword = async () => {
    if (formData.newPassword !== formData.confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    setLoading(true);
    setError(null);
    try {
      await changePassword(formData.newPassword, loginToken);
      setSuccess("Password changed successfully!");
      setStep(1);
      setFormData({
        currentPassword: "",
        otp: "",
        newPassword: "",
        confirmPassword: "",
      });
    } catch (err) {
      setError("Failed to change the password.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <ChangePasswordContext.Provider
      value={{
        step,
        formData,
        loading,
        error,
        success,
        handleChange,
        handleVerifyPassword,
        handleVerifyOtp,
        handleChangePassword,
      }}
    >
      {children}
    </ChangePasswordContext.Provider>
  );
};

export const useChangePassword = () => {
  return useContext(ChangePasswordContext);
};
