import axios from "axios";
import { API_ENDPOINTS } from "../ApiEndPoints";

const submitReviewUrl = `${process.env.REACT_APP_API_URL}${API_ENDPOINTS.SUBMIT_REVIEW}`;
const updateReviewUrl = `${process.env.REACT_APP_API_URL}${API_ENDPOINTS.UPDATE_REVIEW}`;
const fetchReviewsUrl = `${process.env.REACT_APP_API_URL}${API_ENDPOINTS.SHOW_REVIEWS}`;

/**
 * Submit a review for a specific product.
 * @param {number} productId - The ID of the product to submit the review for.
 * @param {string} comment - The review comment.
 * @param {number} rating - The rating (1 to 5).
 * @param {string} token - The JWT token for authorization.
 * @returns {Promise<object>} - Promise resolving to the API response.
 */
export const submitReview = async (
  productId,
  orderId,
  comment,
  rating,
  loginToken
) => {
  try {
    const response = await axios.post(
      submitReviewUrl,
      {
        product_id: productId,
        order_id: orderId,
        comment: comment,
        rating: rating,
      },
      {
        headers: {
          Authorization: `Bearer ${loginToken}`, // Add token in the header for authentication
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error submitting review:", error);
    throw error;
  }
};

export const updateReview = async (
  productId,
  orderId,
  comment,
  rating,
  loginToken
) => {
  try {
    const response = await axios.put(
      updateReviewUrl,
      {
        product_id: productId,
        order_id: orderId,
        comment: comment,
        rating: rating,
      },
      {
        headers: {
          Authorization: `Bearer ${loginToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error submitting review:", error);
    throw error;
  }
};

/**
 * Fetch reviews for a specific product.
 * @param {number} productId - The ID of the product to fetch reviews for.
 * @returns {Promise<object>} - Promise resolving to the API response.
 */
export const fetchReviews = async (productId) => {
  try {
    const response = await axios.get(
      `${fetchReviewsUrl}?productId=${productId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching reviews:", error);
    throw error;
  }
};
