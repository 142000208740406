import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useState, useContext, useEffect, useRef, useCallback } from "react";
import { CartContext } from "../Context/CartContext.js";
import { LikedProductContext } from "../Context/LikedProductContext.js";
import SiteLogo from "../SiteLogo.js";
import LanguageSwitcher from "../locales/LanguageSwitcher";
import { useTranslation } from "react-i18next";
import LoginModel from "./models/LoginModel.js";
import RegisterModel from "./models/RegisterModel.js";
import { useAuth } from "../Context/AuthContext.js";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useCategory } from "../Context/CategoryContext";
import { useSearch } from "../Context/SearchContext.js";
import { event } from "jquery";
import ForgotPasswordModel from "./models/ForgotPasswordModel.js";
import NavbarWithDropdown from "./NavbarWithDropdown.js";
import MobileLinks from "./MobileLinks.js";

function Header() {
  const { t } = useTranslation();
  const { cartItems, clearCart } = useContext(CartContext);
  const { likedItems, clearLikedItems } = useContext(LikedProductContext);
  const { isLoggedIn, logout } = useAuth();
  const Navigate = useNavigate();
  const location = useLocation();
  const [openedDrawer, setOpenedDrawer] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const { handleCategoryClick } = useCategory();
  const { setSearchTerm } = useSearch();
  const [searchQuery, setSearchQuery] = useState("");
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const isHomePage = location.pathname === "/";
  const drawerRef = useRef(null);
  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 250) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.get("login") === "true") {
      setShowLoginModal(true);
    }
  }, [location]);

  const toggleDrawer = () => setOpenedDrawer(!openedDrawer);

  const handleCloseDrawer = () => {
    setOpenedDrawer(false);
  };

  const handleClickOutside = useCallback((event) => {
    if (drawerRef.current && !drawerRef.current.contains(event.target)) {
      setOpenedDrawer(false);
    }
  }, []);

  useEffect(() => {
    if (openedDrawer) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openedDrawer, handleClickOutside]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault(); // Prevents form submission
    setSearchTerm(searchQuery); // Set search term in the context
    handleCategoryClick("All Products"); // Optional: Adjust this based on the default category behavior
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearchSubmit(event); // Trigger search when Enter key is pressed
    }
  };

  const handleLogout = () => {
    logout();

    // Clear the cart and Liked items after successful order
    clearCart();
    clearLikedItems();

    Navigate("/");
    handleCloseDrawer();
  };

  const handleRegisterClick = (e) => {
    e.preventDefault();
    setShowRegisterModal(true);
    setShowLoginModal(false);
  };

  const handleLoginClick = (e) => {
    e.preventDefault();
    setShowLoginModal(true);
    setShowRegisterModal(false);
  };

  const handleForgotPasswordOpen = () => {
    setShowForgotPasswordModal(true);
    setShowLoginModal(false);
  };
  const handleForgotPasswordClose = () => setShowForgotPasswordModal(false);

  const handleCategoryChange = (event) => {
    const selectedCategory = event.target.value; // Get selected category
    handleCategoryClick(selectedCategory); // Update category context and scroll
  };

  const handleDropdownLoginClick = () => {
    setShowLoginModal(true);
  };

  const handleDropdownRegisterClick = () => {
    setShowRegisterModal(true);
  };

  return (
    <>
      <header className="w-auto overflow-hidden">
        <nav className="navbar d-flex flex-column fixed-top navbar-expand-lg scrolled navbar-light bg-white border-bottom">
          <div className="container-fluid">
            <div
              className={`navbar-collapse offcanvas-collapse justify-content-between ${
                openedDrawer ? "open" : ""
              }`}
              ref={drawerRef}
            >
              {/* Conditional Rendering for Search Bar and Links */}
              {isHomePage ? (
                <>
                  <SiteLogo
                    handleCloseDrawer={handleCloseDrawer}
                    className="d-none d-lg-flex"
                  />
                  <div className="search-bar-container col-6 d-flex align-items-center bg-white border shadow-sm px-3 py-1 me-3 d-none d-lg-flex">
                    <select
                      className="form-select border-0 bg-transparent me-2 w-auto border-end"
                      aria-label="Select Category"
                      onChange={(event) =>
                        handleCategoryClick(event.target.value)
                      }
                    >
                      <option value="All">{t("All Categories")}</option>
                      <option value="Medicine">{t("Medicine")}</option>
                      <option value="Dryfruits">{t("Dry-Fruits")}</option>
                      <option value="HealthInstruments">
                        {t("Health Instruments")}
                      </option>
                    </select>
                    <input
                      type="text"
                      className="form-control border-0 bg-transparent text-dark"
                      placeholder={t("Search for products...")}
                      value={searchQuery}
                      onChange={handleSearchChange}
                      onKeyDown={handleKeyDown}
                      aria-label="Search input"
                      style={{ boxShadow: "none" }}
                    />
                    <button
                      className="btn btn-gradient rounded-circle d-flex align-items-center justify-content-center"
                      onClick={handleSearchSubmit}
                      aria-label="Search button"
                      style={{
                        background: "linear-gradient(90deg, #677425, #677425)",
                        color: "white",
                        width: "40px",
                        height: "40px",
                      }}
                    >
                      <FontAwesomeIcon icon={faSearch} />
                    </button>
                  </div>

                  <ul className="d-flex d-lg-none navbar-nav m-auto fw-bold">
                    <li className="nav-item">
                      <Link className="nav-link " to="#">
                        <MobileLinks />
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        to="/about-us"
                        onClick={handleCloseDrawer}
                      >
                        {t("About-Us")}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        to="/contact-us"
                        onClick={handleCloseDrawer}
                      >
                        {t("Contact-Us")}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        to="/faqs"
                        onClick={handleCloseDrawer}
                      >
                        {t("FAQS")}
                      </Link>
                    </li>
                    <li className="nav-item d-lg-none">
                      <Link
                        className="nav-link"
                        to="/terms&condition"
                        onClick={handleCloseDrawer}
                      >
                        {t("Terms & Conditions")}
                      </Link>
                    </li>
                    <li className="nav-item d-lg-none">
                      <Link
                        className="nav-link"
                        to="/refund-policies"
                        onClick={handleCloseDrawer}
                      >
                        {t("Refund Policies")}
                      </Link>
                    </li>
                    <li className="nav-item d-lg-none">
                      <Link
                        className="nav-link"
                        to="/return-policies"
                        onClick={handleCloseDrawer}
                      >
                        {t("Return Policies")}
                      </Link>
                    </li>
                    <li className="nav-item d-lg-none">
                      <Link
                        className="nav-link"
                        to="/ask-a-questions"
                        onClick={handleCloseDrawer}
                      >
                        {t("Support Center")}
                      </Link>
                    </li>
                    <li className="nav-item d-lg-none">
                      <Link
                        className="nav-link"
                        to="/happy-customers"
                        onClick={handleCloseDrawer}
                      >
                        {t("Happy-Customers")}
                      </Link>
                    </li>
                  </ul>
                </>
              ) : (
                <>
                  <SiteLogo
                    handleCloseDrawer={handleCloseDrawer}
                    className="d-none d-lg-flex"
                  />
                  <ul className="d-flex navbar-nav m-auto fw-bold">
                    <li className="nav-item">
                      <Link className="nav-link" to="#">
                        <MobileLinks />
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        to="/about-us"
                        onClick={handleCloseDrawer}
                      >
                        {t("About-Us")}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        to="/contact-us"
                        onClick={handleCloseDrawer}
                      >
                        {t("Contact-Us")}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        to="/faqs"
                        onClick={handleCloseDrawer}
                      >
                        {t("FAQS")}
                      </Link>
                    </li>
                    <li className="nav-item d-lg-none">
                      <Link
                        className="nav-link"
                        to="/terms&condition"
                        onClick={handleCloseDrawer}
                      >
                        {t("Terms & Conditions")}
                      </Link>
                    </li>
                    <li className="nav-item d-lg-none">
                      <Link
                        className="nav-link"
                        to="/refund-policies"
                        onClick={handleCloseDrawer}
                      >
                        {t("Refund Policies")}
                      </Link>
                    </li>
                    <li className="nav-item d-lg-none">
                      <Link
                        className="nav-link"
                        to="/return-policies"
                        onClick={handleCloseDrawer}
                      >
                        {t("Return Policies")}
                      </Link>
                    </li>
                    <li className="nav-item d-lg-none">
                      <Link
                        className="nav-link"
                        to="/ask-a-questions"
                        onClick={handleCloseDrawer}
                      >
                        {t("Support Center")}
                      </Link>
                    </li>
                    <li className="nav-item d-lg-none">
                      <Link
                        className="nav-link"
                        to="/happy-customers"
                        onClick={handleCloseDrawer}
                      >
                        {t("Happy-Customers")}
                      </Link>
                    </li>
                  </ul>
                </>
              )}
              <div className="d-flex flex-column flex-lg-row">
                <div className="me-0">
                  {openedDrawer ? (
                    <ul className="d-flex d-lg-none navbar-nav m-auto fw-bold m-0">
                      <li className="nav-item d-lg-none">
                        <Link
                          className="nav-link"
                          to="/liked-products"
                          onClick={handleCloseDrawer}
                        >
                          {t("My Liked Products")}
                        </Link>
                      </li>
                    </ul>
                  ) : (
                    <Link to="/liked-products">
                      <button
                        type="button"
                        className="btn btn-green-theme rounded-circle me-3 d-lg-inline"
                        aria-label="Liked Products"
                        style={{ position: "relative" }} // Add relative positioning to the button
                      >
                        {/* Heart icon */}
                        <FontAwesomeIcon icon={["fas", "heart"]} />

                        {/* Badge for liked items */}
                        {likedItems.length > 0 && (
                          <span
                            className="badge rounded-pill bg-green-theme"
                            style={{
                              position: "absolute",
                              top: "1px", // Adjust the vertical position
                              right: "1px", // Adjust the horizontal position
                              transform: "translate(50%, -50%)", // Fine-tune alignment
                            }}
                          >
                            {likedItems.length}
                          </span>
                        )}
                      </button>
                    </Link>
                  )}
                  <Link to="/cart">
                    <button
                      type="button"
                      className="btn btn-green-theme rounded-circle me-3 d-none d-lg-inline"
                      aria-label="Cart"
                      style={{ position: "relative" }} // Add relative positioning to the button
                    >
                      {/* Shopping cart icon */}
                      <FontAwesomeIcon icon={["fas", "shopping-cart"]} />

                      {/* Badge for quantity */}
                      {cartItems.length > 0 && (
                        <span
                          className="badge rounded-pill bg-green-theme"
                          style={{
                            position: "absolute",
                            top: "1px", // Adjust the vertical position
                            right: "1px", // Adjust the horizontal position
                            transform: "translate(50%, -50%)", // Fine-tune alignment
                          }}
                        >
                          {cartItems.length}
                        </span>
                      )}
                    </button>
                  </Link>
                </div>
                <div className="d-lg-flex">
                  {openedDrawer ? (
                    <div className="d-flex">
                      <ul className="d-flex d-lg-none navbar-nav fw-bold m-0">
                        <li className="nav-item d-lg-none">
                          <Link
                            className="nav-link d-flex align-items-start"
                            to="#"
                          >
                            <span className="pe-3">
                              {t("Select Your Langauge:")}
                            </span>
                            <LanguageSwitcher
                              handleCloseDrawer={handleCloseDrawer}
                            />
                          </Link>
                        </li>
                      </ul>
                    </div>
                  ) : (
                    <LanguageSwitcher handleCloseDrawer={handleCloseDrawer} />
                  )}
                  <ul className="navbar-nav d-none d-lg-flex mb-2 mb-lg-0 ">
                    {isLoggedIn ? (
                      <li className="nav-item dropdown">
                        <Link
                          to="#"
                          className="nav-link dropdown-toggle text-green-theme"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <FontAwesomeIcon
                            size="lg"
                            icon={["fas", "user-circle"]}
                          />
                        </Link>
                        <ul className="dropdown-menu dropdown-menu-end shadow-sm rounded-0 position-absolute mt-4 me-1 p-2">
                          <li>
                            <Link
                              to="/user-profile"
                              onClick={() => handleCloseDrawer()}
                              className="dropdown-item d-flex align-items-center gap-3 fs-6 text-primary fw-semibold hover-bg-light"
                            >
                              <i className="bi bi-person-circle fs-6"></i>
                              Profile
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="#"
                              className="dropdown-item d-flex align-items-center gap-3 fs-6 text-danger fw-semibold hover-bg-light"
                              onClick={handleLogout}
                            >
                              <i className="bi bi-box-arrow-right fs-6"></i>
                              Logout
                            </Link>
                          </li>
                        </ul>
                      </li>
                    ) : (
                      <li className="nav-item dropdown">
                        <Link
                          to="#"
                          className="nav-link dropdown-toggle text-green-theme"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <FontAwesomeIcon
                            size="lg"
                            icon={["fas", "user-alt"]}
                          />
                        </Link>
                        <ul className="dropdown-menu dropdown-menu-end shadow-sm rounded-0 mt-4 me-1">
                          <li>
                            <Link
                              to="#"
                              className="dropdown-item d-flex align-items-center gap-2 fs-6 fw-bold text-primary fw-semibold"
                              onClick={() => handleDropdownLoginClick()}
                            >
                              <i className="bi bi-box-arrow-in-right fs-6 fw-bold"></i>
                              Login
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="#"
                              className="dropdown-item d-flex align-items-center gap-2 fw-bold fs-6 text-primary fw-semibold"
                              onClick={() => handleDropdownRegisterClick()}
                            >
                              <i className="bi bi-person-plus fs-6 fw-bold"></i>
                              Sign Up
                            </Link>
                          </li>
                        </ul>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>

            <div className="navbar d-flex w-100 justify-content-between d-lg-none align-items-center">
              <button
                className="navbar-toggler p-0 border-0 ms-3"
                type="button"
                onClick={toggleDrawer}
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <SiteLogo handleCloseDrawer={handleCloseDrawer} />
              <div className="d-flex">
                <Link to="/cart">
                  <button
                    type="button"
                    className="btn btn-green-theme rounded-circle me-0 mt-1"
                    aria-label="Cart"
                    style={{ position: "relative" }} // Add relative positioning to the button
                  >
                    {/* Shopping cart icon */}
                    <FontAwesomeIcon icon={["fas", "shopping-cart"]} />

                    {/* Badge for quantity */}
                    {cartItems.length > 0 && (
                      <span
                        className="badge rounded-pill bg-green-theme"
                        style={{
                          position: "absolute",
                          top: "1px", // Adjust the vertical position
                          right: "1px", // Adjust the horizontal position
                          transform: "translate(50%, -50%)", // Fine-tune alignment
                        }}
                      >
                        {cartItems.length}
                      </span>
                    )}
                  </button>
                </Link>
                {/* move to toggler */}
                <ul className="navbar-nav mb-2 mb-lg-0 px-3">
                  {isLoggedIn ? (
                    <li className="nav-item dropdown">
                      <Link
                        to="#"
                        className="nav-link dropdown-toggle text-green-theme"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <FontAwesomeIcon
                          size="lg"
                          icon={["fas", "user-circle"]}
                        />
                      </Link>
                      <ul className="dropdown-menu dropdown-menu-end shadow-sm rounded-0 position-absolute mt-4 me-1 p-2">
                        <li>
                          <Link
                            to="/user-profile"
                            onClick={() => handleCloseDrawer()}
                            className="dropdown-item d-flex align-items-center gap-3 fs-6 text-primary fw-semibold hover-bg-light"
                          >
                            <i className="bi bi-person-circle fs-6"></i>
                            Profile
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="#"
                            className="dropdown-item d-flex align-items-center gap-3 fs-6 text-danger fw-semibold hover-bg-light"
                            onClick={handleLogout}
                          >
                            <i className="bi bi-box-arrow-right fs-6"></i>
                            Logout
                          </Link>
                        </li>
                      </ul>
                    </li>
                  ) : (
                    <li className="nav-item dropdown">
                      <Link
                        to="#"
                        className="nav-link dropdown-toggle text-green-theme"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <FontAwesomeIcon size="lg" icon={["fas", "user-alt"]} />
                      </Link>
                      <ul className="dropdown-menu dropdown-menu-end shadow-sm rounded-0 position-absolute mt-4 p-2">
                        <li>
                          <Link
                            to="#"
                            className="dropdown-item d-flex align-items-center gap-2 fs-6 fw-bold text-primary fw-semibold"
                            onClick={() => handleDropdownLoginClick()}
                          >
                            <i className="bi bi-box-arrow-in-right fs-6 fw-bold"></i>
                            Login
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="#"
                            className="dropdown-item d-flex align-items-center gap-2 fw-bold fs-6 text-primary fw-semibold"
                            onClick={() => handleDropdownRegisterClick()}
                          >
                            <i className="bi bi-person-plus fs-6 fw-bold"></i>
                            Sign Up
                          </Link>
                        </li>
                      </ul>
                    </li>
                  )}
                </ul>
                {/* move to toggler */}
              </div>
            </div>
          </div>
          <div className="container d-flex justify-content-center">
            <NavbarWithDropdown isHomePage={isHomePage} />
          </div>
        </nav>
        {isHomePage ? (
          <div
            className={`search-bar-mobile-container d-flex d-lg-none ${
              scrolling ? "d-none" : ""
            }`}
          >
            <input
              type="text"
              placeholder="Search"
              className="search-input"
              value={searchQuery}
              onChange={handleSearchChange}
              aria-label="Search input"
            />
            <button
              className="search-button"
              onClick={handleSearchSubmit}
              aria-label="Search button"
            >
              <FontAwesomeIcon icon={faSearch} />
            </button>
          </div>
        ) : (
          ""
        )}
      </header>

      <LoginModel
        handleRegisterClick={handleRegisterClick}
        showLoginModal={showLoginModal}
        handleCloseModal={() => setShowLoginModal(false)}
        handleForgotPasswordOpen={handleForgotPasswordOpen}
      />
      <RegisterModel
        handleLoginClick={handleLoginClick}
        showRegisterModal={showRegisterModal}
        handleCloseModal={() => setShowRegisterModal(false)}
      />

      {showForgotPasswordModal && (
        <ForgotPasswordModel
          key={showForgotPasswordModal}
          show={showForgotPasswordModal}
          handleClose={handleForgotPasswordClose}
        />
      )}
    </>
  );
}

export default Header;
