import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect, useRef } from "react";
import { products } from "./productCategories";

const MobileLinks = () => {
  const [showCategories, setShowCategories] = useState(false); // Toggle all categories
  const [selectedCategory, setSelectedCategory] = useState(null); // Toggle specific category
  const containerRef = useRef(null); // Ref to track the component
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 992); // Track screen size

  // Handle toggling the entire categories menu
  const toggleCategories = () => {
    setShowCategories(!showCategories); // Toggle categories visibility
    if (showCategories) {
      setSelectedCategory(null); // Reset selected category when closing
    }
  };

  // Handle toggling a specific category
  const toggleCategory = (category) => {
    if (selectedCategory === category) {
      setSelectedCategory(null); // Close the category if already open
    } else {
      setSelectedCategory(category); // Open the selected category
    }
  };

  // Handle closing when clicking outside the component
  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setShowCategories(false); // Hide all categories
      setSelectedCategory(null); // Reset selected category
    }
  };

  // Handle resizing and switching between mobile and desktop views
  const handleResize = () => {
    const isNowMobileView = window.innerWidth <= 992;
    if (isMobileView !== isNowMobileView) {
      setIsMobileView(isNowMobileView);
      if (!isNowMobileView) {
        // Reset categories and slider when switching to desktop
        setShowCategories(false);
        setSelectedCategory(null);
      }
    }
  };

  // Add event listeners for clicks and window resizing
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    window.addEventListener("resize", handleResize);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobileView]);

  return (
    <div className="mobile-links-container">
      <div
        className="mobile-links d-flex d-lg-none flex-column"
        ref={containerRef}
      >
        {/* Toggle main categories */}
        <span className="our-products-link" onClick={toggleCategories}>
          Our Products
          <span className="ps-2 pt-1">
            {showCategories ? (
              <FontAwesomeIcon icon={faAngleUp} />
            ) : (
              <FontAwesomeIcon icon={faAngleDown} />
            )}
          </span>
        </span>

        {/* Render categories only if `showCategories` is true */}
        {showCategories && (
          <div className="categories">
            <span
              className="category-link"
              onClick={() => toggleCategory("medicine")}
            >
              - Medicine
            </span>
            {selectedCategory === "medicine" && (
              <div className="mobile-slider">
                <div
                  className={`${
                    showCategories
                      ? "slider-container d-flex gap-4 overflow-auto"
                      : "slider-container "
                  }`}
                >
                  {products.medicine.map((product) => (
                    <div key={product.id} className="slider-item">
                      <img src={product.image} alt={product.name} />
                      <h5>{product.name}</h5>
                    </div>
                  ))}
                </div>
              </div>
            )}

            <span
              className="category-link"
              onClick={() => toggleCategory("dryFruits")}
            >
              - Dry Fruits
            </span>
            {selectedCategory === "dryFruits" && (
              <div className="mobile-slider">
                <div
                  className={`${
                    showCategories
                      ? "slider-container d-flex gap-4 overflow-auto"
                      : "slider-container "
                  }`}
                >
                  {products.dryFruits.map((product) => (
                    <div key={product.id} className="slider-item">
                      <img src={product.image} alt={product.name} />
                      <h5>{product.name}</h5>
                    </div>
                  ))}
                </div>
              </div>
            )}

            <span
              className="category-link"
              onClick={() => toggleCategory("healthInstruments")}
            >
              - Health Instruments
            </span>
            {selectedCategory === "healthInstruments" && (
              <div className="mobile-slider">
                <div
                  className={`${
                    showCategories
                      ? "slider-container d-flex gap-4 overflow-auto"
                      : "slider-container "
                  }`}
                >
                  {products.healthInstruments.map((product) => (
                    <div key={product.id} className="slider-item">
                      <img src={product.image} alt={product.name} />
                      <h5>{product.name}</h5>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      {/* Inline styles */}
      <style>{`
        .mobile-links-container {
          display: flex;
          flex-direction: column;
          background-color: #f9f9f9;
        }
        .our-products-link {
          font-size: 16px;
          cursor: pointer;
          color: #212529;
        }
        .categories {
          margin-top: 10px;
          display: flex;
          flex-direction: column;
          gap: 10px;
        }
        .category-link {
          font-size: 14px;
          color: #555;
          cursor: pointer;
        }
        .category-link:hover {
          color: #000;
        }
        .mobile-slider {
          margin: 10px 0;
        }
        .slider-item {
          flex: 0 0 auto;
          text-align: center;
          width: 250px;
        }
        .slider-item img {
          width: 100%;
          height: 120px;
          object-fit: cover;
          border-radius: 8px;
        }
        .slider-item h5 {
          margin-top: 5px;
          font-size: 14px;
          color: #212529;
        }
      `}</style>
    </div>
  );
};

export default MobileLinks;
