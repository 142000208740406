import { useContext, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ProductContext } from "../Context/ProductContext";

function ProductCard() {
  const { t } = useTranslation();
  const { products } = useContext(ProductContext);

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <>
      <hr />
      <h3 className="container px-4 text-bold">{t("best-products")}</h3>
      <hr />
      <div className="container p-4">
        <Swiper
          modules={[Navigation, Pagination, Autoplay]}
          spaceBetween={20}
          slidesPerView={1}
          breakpoints={{
            576: { slidesPerView: 1 },
            768: { slidesPerView: 2 },
            992: { slidesPerView: 3 },
          }}
          loop={true}
          autoplay={{ delay: 5000 }}
          pagination={{ clickable: true }}
        >
          {products.slice(0, 4).map((item) => {
            const realPrice = item?.price || item?.variation_options[0]?.price;
            const higherPrice = (realPrice * 1.2).toFixed(2); // 20% higher price

            return (
              <SwiperSlide key={item.productId}>
                <Card
                  className="card-hover border-radius-none mb-5 position-relative equal-height-card"
                  data-aos="fade-right"
                >
                  {/* Price Badge */}
                  <div
                    className="price-badge border-rad-none"
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                      backgroundColor: "#677425",
                      color: "white",
                      padding: "5px 10px",
                      borderRadius: "5px",
                      fontSize: "1rem",
                      fontWeight: "bold",
                    }}
                  >
                    €{realPrice}
                  </div>
                  <Link
                    to={`/products/${item.productId}`}
                    state={{ product: item.product }}
                    replace
                  >
                    <Card.Img
                      variant="top"
                      src={item.image.original}
                      style={{
                        width: "200px",
                        height: "200px",
                        objectFit: "contain",
                        margin: "auto",
                        // marginTop: "25px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    />
                  </Link>
                  <Card.Body className="d-flex flex-column">
                    <div className="d-flex justify-content-between align-items-center">
                      <Card.Title>{item.name}</Card.Title>
                    </div>
                    <div className="text-warning font-bold-star">
                      {Array.from({ length: 5 }, (_, index) => (
                        <span
                          key={index}
                          className={
                            index < Math.round(item.averageRating)
                              ? "text-warning"
                              : "text-muted"
                          }
                        >
                          ★
                        </span>
                      ))}
                    </div>
                    <div className="price-section">
                      <span
                        style={{
                          color: "red",
                          fontWeight: "bold",
                          textDecoration: "line-through",
                          marginRight: "8px",
                        }}
                      >
                        €{higherPrice}
                      </span>
                      <span style={{ fontWeight: "bold" }}>{t("20%-off")}</span>
                    </div>
                    <hr />
                    <Card.Text>{item.description.slice(0, 200)}</Card.Text>
                  </Card.Body>
                </Card>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </>
  );
}

export default ProductCard;
