// src/services/addressServices.js
import { API_ENDPOINTS } from "../ApiEndPoints";

const addAddressUrl = `${process.env.REACT_APP_API_URL}${API_ENDPOINTS.ADDRESS}`;
const updateAddressUrl = `${process.env.REACT_APP_API_URL}${API_ENDPOINTS.UPDATE_ADDRESS}`;
const deleteAddressUrl = `${process.env.REACT_APP_API_URL}${API_ENDPOINTS.DELETE_ADDRESS}`;
const showAddressUrl = `${process.env.REACT_APP_API_URL}${API_ENDPOINTS.SHOW_ADDRESS}`;
const fetchCountryUrl = `${process.env.REACT_APP_API_URL}${API_ENDPOINTS.COUNTRY_CITY}`;

// Function to fetch country and city data
export const fetchCountryCity = async () => {
  try {
    const response = await fetch(fetchCountryUrl, {
      headers: {
        "Cache-Control": "no-cache",
      },
    });

    if (!response.ok) {
      throw new Error(
        `Failed to fetch country and city data: ${response.statusText}`
      );
    }

    const result = await response.json();
    return result.map((country) => ({
      id: country.id,
      name: country.name,
      cities: country.cities || [], // Ensure cities is always an array
    }));
  } catch (error) {
    console.error("Error fetching country and city data:", error);
    throw error;
  }
};

// Function to fetch address, shipping methods, and payment gateways
export const fetchAddressData = async (loginToken) => {
  try {
    const response = await fetch(showAddressUrl, {
      headers: {
        Authorization: `Bearer ${loginToken}`,
        "Cache-Control": "no-cache",
      },
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch address data: ${response.statusText}`);
    }

    const result = await response.json();
    return {
      addresses: result.addresses.map((address) => ({
        id: address.id,
        type: address.type,
        title: address.title,
        street: address.street,
        country: address.country,
        phonenumber: address.phonenumber,
        defaultaddress: address.defaultaddress,
        city: address.city,
        pincode: address.pincode,
      })),
      shippingMethods: result.shipping.map((shipping) => ({
        id: shipping.id,
        name: shipping.name,
        amount: shipping.amount,
        image: shipping.image.original,
      })),
      paymentGateways: result.paymentGateway.map((payment) => ({
        name: payment.name,
        title: payment.title,
      })),
    };
  } catch (error) {
    console.error("Error fetching address data:", error);
    throw error;
  }
};

// Add a new address
export const addAddress = async (loginToken, addressData) => {
  try {
    const response = await fetch(addAddressUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${loginToken}`,
      },
      body: JSON.stringify(addressData),
    });

    if (!response.ok) {
      throw new Error("Failed to add address");
    }

    const result = await response.json();
    return result; // Return the added address data
  } catch (error) {
    console.error("Error adding address:", error);
    throw error;
  }
};

// Update an existing address
export const updateAddress = async (loginToken, addressId, addressData) => {
  try {
    const response = await fetch(`${updateAddressUrl}/${addressId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${loginToken}`,
      },
      body: JSON.stringify(addressData),
    });

    if (!response.ok) {
      throw new Error("Failed to update address");
    }

    const result = await response.json();
    return result; // Return the updated address data
  } catch (error) {
    console.error("Error updating address:", error);
    throw error;
  }
};

// Delete an address on the server
export const deleteAddressOnServer = async (loginToken, addressId) => {
  try {
    const response = await fetch(`${deleteAddressUrl}/${addressId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${loginToken}`,
      },
    });
    if (!response.ok) throw new Error("Failed to delete address");
  } catch (error) {
    console.error("Error deleting address:", error);
    throw error;
  }
};
