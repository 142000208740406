import axios from "axios";
import { API_ENDPOINTS } from "../ApiEndPoints";

const productUrl = `${process.env.REACT_APP_API_URL}${API_ENDPOINTS.PRODUCTS}`;

// Function to fetch products
export const fetchProducts = async (language) => {
  try {
    const response = await axios.get(productUrl, {
      params: { language },
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data.product; // Assuming response.data.product contains product list
  } catch (error) {
    console.error("Error fetching products:", error);
    throw error.response?.data?.message || "Failed to load products"; // Return error message
  }
};
