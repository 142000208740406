import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import { useSnackbar } from "notistack";
import { API_ENDPOINTS } from "../../src/ApiEndPoints";
import { useLocation, useNavigate } from "react-router-dom";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loginToken, setLoginToken] = useState(localStorage.getItem("token")); // Store token in state
  const { enqueueSnackbar } = useSnackbar();
  const Navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (loginToken) {
      const decodedToken = JSON.parse(atob(loginToken.split(".")[1])); // Decode JWT
      const expirationTime = decodedToken.exp * 1000; // Expiration time in milliseconds
      const currentTime = Date.now();

      if (expirationTime > currentTime) {
        setIsLoggedIn(true);
      } else {
        logout();
      }
    } else {
      setIsLoggedIn(false);
    }
    setLoading(false);
  }, [loginToken]); // Re-run when loginToken changes

  const logout = () => {
    localStorage.removeItem("token");
    setLoginToken(null); // Clear token from state
    setIsLoggedIn(false);
    setTimeout(() => {
      Navigate("/");
    }, 0);
  };

  const handleLoginSubmit = async (
    email,
    password,
    handleCloseModal,
    setEmail,
    setPassword
  ) => {
    setLoading(true); // Set loading to true immediately
    const loginUrl = `${process.env.REACT_APP_API_URL}${API_ENDPOINTS.LOGIN}`;

    try {
      const response = await axios.post(loginUrl, { email, password });
      const pathName = location.pathname;

      if (response.status === 200) {
        const token = response.data.token;
        localStorage.setItem("token", token);
        setLoginToken(token); // Store token in state
        enqueueSnackbar("Login successful!", {
          variant: "success",
          autoHideDuration: 1500,
        });

        handleCloseModal();
        setEmail("");
        setPassword("");
        setIsLoggedIn(true);
        Navigate(`${pathName}`);
      } else {
        enqueueSnackbar("Login failed. Please check your credentials.", {
          variant: "error",
          autoHideDuration: 1500,
        });
      }
    } catch (error) {
      if (error.response) {
        console.error("Server error:", error.response.data);
        enqueueSnackbar("Login failed. Please check your credentials.", {
          variant: "error",
          autoHideDuration: 1500,
        });
      } else if (error.request) {
        console.error("Network error:", error.request);
        enqueueSnackbar("Network error. Please try again later.", {
          variant: "error",
          autoHideDuration: 1500,
        });
      } else {
        console.error("Error:", error.message);
        enqueueSnackbar("An unexpected error occurred. Please try again.", {
          variant: "error",
          autoHideDuration: 1500,
        });
      }
    } finally {
      setLoading(false); // Ensure loading is set to false after the process
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        loginToken, // Provide the loginToken to other components
        logout,
        loading,
        handleLoginSubmit,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => React.useContext(AuthContext);
