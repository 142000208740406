import React, { useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import customerService from "../../img/startup-594090_640.jpg";
import { API_ENDPOINTS } from "../../ApiEndPoints";
import { useSnackbar } from "notistack";

const AskQuestions = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  // Separate state for each field
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [category, setCategory] = useState("");
  const [question, setQuestion] = useState("");
  const [agree, setAgree] = useState(false);
  const [profileImage, setProfileImage] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const categories = ["Medicine", "Dry Fruits", "Health Instruments"];

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    switch (name) {
      case "name":
        setName(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "phone":
        setPhone(value);
        break;
      case "category":
        setCategory(value);
        break;
      case "question":
        setQuestion(value);
        break;
      case "agree":
        setAgree(checked);
        break;
      default:
        break;
    }
  };

  const handleFileChange = (e) => {
    setProfileImage(e.target.files[0]);
  };

  const handleImageUpload = async (image) => {
    if (!image) return null; // No image to upload

    const attachmentUrl = `${process.env.REACT_APP_API_URL}${API_ENDPOINTS.ATTACHMENTS}`;
    const imageFormData = new FormData();
    imageFormData.append("attachment", image);

    try {
      const imageResponse = await axios.post(attachmentUrl, imageFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (imageResponse.status === 201) {
        const imageData = imageResponse.data[0];
        return imageData.id; // Return the avatar_id if successful
      } else {
        throw new Error("Image upload failed.");
      }
    } catch (error) {
      enqueueSnackbar("Profile image upload failed. Please try again later.", {
        variant: "error",
        autoHideDuration: 1500,
      });
      throw error;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const askAquestionUrl = `${process.env.REACT_APP_API_URL}${API_ENDPOINTS.ASK_A_QUESTION}`;

    try {
      // Step 1: Upload the profile image if selected
      const image_id = await handleImageUpload(profileImage);

      // Step 2: Create FormData for question submission
      const questionFormData = {
        name,
        email,
        phone,
        category,
        question,
        image_id: image_id,
      };

      // Step 4: Submit the question form
      const questionResponse = await axios.post(
        askAquestionUrl,
        questionFormData
      );

      if (questionResponse.status === 201) {
        enqueueSnackbar("Your question has been submitted successfully!");
        // Reset form data after submission
        setName("");
        setEmail("");
        setPhone("");
        setCategory("");
        setQuestion("");
        setProfileImage(null);
      } else {
        throw new Error("Question submission failed.");
      }
    } catch (error) {
      alert("There was an unexpected error. Please try again later.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <div className="container-fluid faq-heading">
        <div className="row">
          <h1>Ask a Question</h1>
          <p>{t("We’re-here-to-help!-contact")}</p>
        </div>
      </div>
      <div className="container my-2">
        <div className="row align-items-center">
          <div className="col-12 col-md-6 order-2 order-md-1">
            <form className="p-4 border rounded" onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  value={name}
                  onChange={handleChange}
                  placeholder="Enter your name"
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  value={email}
                  onChange={handleChange}
                  placeholder="Enter your email"
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="phone" className="form-label">
                  Phone
                </label>
                <input
                  type="tel"
                  className="form-control"
                  id="phone"
                  name="phone"
                  value={phone}
                  onChange={handleChange}
                  placeholder="Enter your phone number"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="category" className="form-label">
                  Category
                </label>
                <select
                  className="form-select"
                  id="category"
                  name="category"
                  value={category}
                  onChange={handleChange}
                  required
                >
                  <option value="" disabled>
                    Select a category
                  </option>
                  {categories.map((cat, index) => (
                    <option key={index} value={cat}>
                      {cat}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-3">
                <label htmlFor="attachment" className="form-label">
                  Attach File (Optional)
                </label>
                <input
                  type="file"
                  className="form-control"
                  id="attachment"
                  name="attachment"
                  onChange={handleFileChange}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="question" className="form-label">
                  Question
                </label>
                <textarea
                  className="form-control"
                  id="question"
                  name="question"
                  value={question}
                  onChange={handleChange}
                  rows="4"
                  placeholder="Type your question here"
                  required
                ></textarea>
              </div>
              <div className="mb-3 form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="agree"
                  name="agree"
                  checked={agree}
                  onChange={handleChange}
                  required
                />
                <label htmlFor="agree" className="form-check-label">
                  I agree to the terms and conditions.
                </label>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  type="submit"
                  className="btn btn-green-theme w-75"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Submitting..." : "Submit Question"}
                </button>
              </div>
            </form>
          </div>

          <div className="col-12 col-md-6 text-center order-1 order-md-2">
            <img
              src={customerService}
              alt="Ask Question Illustration"
              className="img-fluid rounded"
            />
            <p className="mt-3">
              We are here to help. Submit your questions, and our team will get
              back to you!
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default AskQuestions;
