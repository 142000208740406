import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import axios from "axios";
import { useSnackbar } from "notistack";
import { ClipLoader } from "react-spinners";
import { API_ENDPOINTS } from "../../ApiEndPoints";
import OtpVerificationModal from "./OtpVerificationModal";
import { error } from "jquery";

const RegisterModal = ({
  showRegisterModal,
  handleCloseModal,
  handleLoginClick,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [firstname, setfirstname] = useState("");
  const [lastname, setlastName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [profileImage, setProfileImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [openOtpModal, setOpenOtpModal] = useState(false);
  const [otpSubmitHandler, setOtpSubmitHandler] = useState(null);
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [errorMessage, setErrorMessage] = useState("");

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  // Handle file selection
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfileImage(file);
    }
  };

  const handleImageUpload = async () => {
    const attachmentUrl = `${process.env.REACT_APP_API_URL}${API_ENDPOINTS.ATTACHMENTS}`;
    const imageFormData = new FormData();
    imageFormData.append("attachment", profileImage);

    try {
      const imageResponse = await axios.post(attachmentUrl, imageFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (imageResponse.status === 201) {
        const imageData = imageResponse.data[0];
        return imageData.id; // Return the avatar_id if successful
      } else {
        throw new Error("Image upload failed.");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      enqueueSnackbar("Profile image upload failed. Please try again later.", {
        variant: "error",
        autoHideDuration: 1500,
      });
      throw error; // Propagate the error so it can be handled in the parent function
    }
  };

  const handleOtpSubmit = async (otp) => {
    const verifyOtpUrl = `${process.env.REACT_APP_API_URL}${API_ENDPOINTS.VERIFY_OTP}`;
    const avatarId = await handleImageUpload();

    try {
      const otpVerificationResponse = await axios.post(verifyOtpUrl, {
        firstname,
        lastname,
        email,
        contact,
        password,
        avatar_id: avatarId,
        otp,
      });

      if (otpVerificationResponse.status === 200) {
        // Finalize registration after successful OTP verification
        enqueueSnackbar(" Email verified and registration complete!", {
          variant: "success",
          autoHideDuration: 1500,
        });

        // Close OTP modal
        setOpenOtpModal(false);
        handleCloseModal();

        // Reset form fields
        setfirstname("");
        setlastName("");
        setEmail("");
        setContact("");
        setPassword("");
        setProfileImage(null);

        // Optionally, clear OTP from localStorage after successful verification
        // localStorage.removeItem("otp");
      } else {
        // Handle OTP failure and pass error message back to the modal
        throw new Error("OTP verification failed");
      }
    } catch (error) {
      // Handle errors and send status to parent component
      setErrorMessage("Enter valid code!");
      setOtp(new Array(6).fill(""));
      enqueueSnackbar("OTP verification failed. Please add valid code.", {
        variant: "error",
        autoHideDuration: 1500,
      });
    }
  };

  const handleRegisterSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true immediately

    if (!termsAccepted) {
      alert("You must accept the terms and conditions to register.");
      return;
    }

    // Wait for 1.5 seconds before proceeding
    await new Promise((resolve) => setTimeout(resolve, 1500));
    const registerUrl = `${process.env.REACT_APP_API_URL}${API_ENDPOINTS.REGISTER}`;

    try {
      // Step 1: Upload the profile image
      const avatarId = await handleImageUpload(); // Call the image upload function

      // Step 2: Register the user without completing registration
      const registrationData = {
        firstname,
        lastname,
        email,
        contact,
        password,
        avatarId: avatarId, // Include avatar_id here
      };

      const response = await axios.post(registerUrl, registrationData);

      if (response.status === 201) {
        enqueueSnackbar(" Registration successful!", {
          variant: "success",
          autoHideDuration: 1500,
        });

        // Open OTP modal immediately after registration
        setOpenOtpModal(true); // Trigger OTP modal to open
      } else {
        enqueueSnackbar("Registration failed. Please try again.", {
          variant: "error",
          autoHideDuration: 1500,
        });
      }
    } catch (error) {
      // Handle errors here (e.g., image upload failure, registration failure)
      console.error("Error during registration:", error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  if (!showRegisterModal) return null;

  return (
    <>
      {/* Background Overlay */}
      <div className="overlay-custom" onClick={handleCloseModal}></div>
      <div
        className="modal fade show"
        tabIndex="-1"
        style={{ display: "block" }}
      >
        <div className="modal-dialog p-3 mt-5 mt-md-3 modal-dialog-centered modal-lg justify-content-center">
          <div className="modal-content mt-5 text-start p-0 border-radius-none">
            <div className="modal-header ">
              <h5 className="modal-title">{t("Create-an-Account")}</h5>
              <button
                type="button"
                className="btn-close"
                onClick={handleCloseModal}
              ></button>
            </div>
            <div className="modal-body row">
              <div className="col-md-12">
                <form onSubmit={handleRegisterSubmit}>
                  <div className="mb-3 d-flex flex-column flex-md-row">
                    <div className="col-12 col-md-6 pe-md-3">
                      <label htmlFor="name" className="form-label">
                        First Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="firstname"
                        placeholder={t("Enter-your-first-name")}
                        value={firstname}
                        onChange={(e) => setfirstname(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <label htmlFor="name" className="form-label">
                        Last Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="lastname"
                        placeholder={t("Enter-your-last-name")}
                        value={lastname}
                        onChange={(e) => setlastName(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className="mb-3 d-flex flex-column flex-md-row">
                    <div className="col-12 col-md-6 pe-md-3">
                      <label htmlFor="email" className="form-label">
                        {t("Email-address")}
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder={t("Enter-your-email")}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <label htmlFor="contact" className="form-label">
                        {t("Contact")}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="contact"
                        placeholder={t("Enter-your-contact-number")}
                        value={contact}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (/^\d{0,10}$/.test(value)) {
                            setContact(value);
                          }
                        }}
                        pattern="\d{10}"
                        required
                      />
                    </div>
                  </div>
                  <div className="mb-3 d-flex flex-column flex-md-row">
                    <div className="col-12 col-md-6 pe-md-3">
                      <label htmlFor="attachment" className="form-label">
                        {t("Profile Image")}
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        id="attachment"
                        accept="image/*"
                        onChange={handleImageChange}
                        required
                      />
                    </div>
                    <div className="col-12 col-md-6 position-relative">
                      <label htmlFor="password" className="form-label">
                        {t("Password")}
                      </label>
                      <input
                        type={passwordVisible ? "text" : "password"}
                        className="form-control pe-5"
                        id="password"
                        placeholder={t("Enter-your-Password")}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                      <span
                        className="position-absolute eye-position translate-middle-y me-3"
                        style={{ cursor: "pointer" }}
                        onClick={togglePasswordVisibility}
                      >
                        {passwordVisible ? (
                          <FontAwesomeIcon icon={faEyeSlash} />
                        ) : (
                          <FontAwesomeIcon icon={faEye} />
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="mb-3 form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="termsAndConditions"
                      checked={termsAccepted}
                      onChange={(e) => setTermsAccepted(e.target.checked)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="termsAndConditions"
                    >
                      {t("I-hereby-accept-the")}{" "}
                      <Link to="/terms&condition" target="_blank">
                        {t("terms-and-conditions")}
                      </Link>
                    </label>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-green-theme col-6 col-md-4"
                    disabled={loading}
                  >
                    {loading ? (
                      <div style={{ width: "60px" }}>
                        <ClipLoader
                          color={"#000"}
                          loading={loading}
                          size={24}
                        />
                      </div>
                    ) : (
                      t("Register")
                    )}
                  </button>
                </form>

                {/* Login Link */}
                <div className="mt-0 pt-3 text-start text-md-center">
                  <p className="mb-0">
                    {t("Already-a-User?")}{" "}
                    <Link to="#" onClick={handleLoginClick}>
                      {t("Login")}
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* OTP Verification Modal */}
      <OtpVerificationModal
        open={openOtpModal}
        onClose={() => setOpenOtpModal(false)}
        handleOtpSubmit={handleOtpSubmit}
        otp={otp}
        setOtp={setOtp}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
      />
    </>
  );
};

export default RegisterModal;
