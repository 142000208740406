import React, { Suspense } from "react";
import Template from "./template/Template";
import "./custom.css";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import AllRoutes from "./routes";
import { AuthProvider } from "./Context/AuthContext";
import ScrollToTopButton from "./ScrollToTopButton";

function App() {
  return (
    <AuthProvider>
      <I18nextProvider i18n={i18n}>
        <Suspense fallback={<div>Loading translations...</div>}>
          <Template>
            <AllRoutes />
            <ScrollToTopButton />
          </Template>
        </Suspense>
      </I18nextProvider>
    </AuthProvider>
  );
}

export default App;
