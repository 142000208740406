import React from "react";
import { CartProvider } from "../CartContext";
import { LikedProductProvider } from "../LikedProductContext";
import { ProductProvider } from "../ProductContext";
import PlaceOrderProvider from "../PlaceOrderContext";
import { AddressProvider } from "../AddressContext";
import { UserProfileProvider } from "../UserProfileContext";
import MyOrderProvider from "../MyOrderContext";
import { CategoryProvider } from "../CategoryContext";
import { ContactUsProvider } from "../ContextUsContext";
import { AuthProvider } from "../AuthContext";
import { SearchProvider } from "../SearchContext";
import { LanguageProvider } from "../LanguageContext";
import { CustomerReviewProvider } from "../CustomerReviewContext";
import { ChangePasswordProvider } from "../ChangePasswordContext";
import { SettingsProvider } from "../SettingsContext";
import { PagesProvider } from "../pagesContext";
import { BlogsProvider } from "../blogContext";

export const ContextProvider = ({ children }) => {
  return (
    <AuthProvider>
      <SettingsProvider>
        <LanguageProvider>
          <CustomerReviewProvider>
            <CategoryProvider>
              <MyOrderProvider>
                <UserProfileProvider>
                  <ChangePasswordProvider>
                    <AddressProvider>
                      <CartProvider>
                        <PlaceOrderProvider>
                          <ProductProvider>
                            <LikedProductProvider>
                              <ContactUsProvider>
                                <BlogsProvider>
                                  <PagesProvider>
                                    <SearchProvider>{children}</SearchProvider>
                                  </PagesProvider>
                                </BlogsProvider>
                              </ContactUsProvider>
                            </LikedProductProvider>
                          </ProductProvider>
                        </PlaceOrderProvider>
                      </CartProvider>
                    </AddressProvider>
                  </ChangePasswordProvider>
                </UserProfileProvider>
              </MyOrderProvider>
            </CategoryProvider>
          </CustomerReviewProvider>
        </LanguageProvider>
      </SettingsProvider>
    </AuthProvider>
  );
};
