import React, { createContext, useContext, useState, useEffect } from "react";
import {
  getTermsAndConditions,
  getRefundPolicies,
  getReturnPolicies,
} from "../apiServices/pagesServices"; // Import your API functions

// Create Context
const PagesContext = createContext();

// Provider Component
const PagesProvider = ({ children }) => {
  // States for the pages data
  const [termsAndConditions, setTermsAndConditions] = useState(null);
  const [refundPolicies, setRefundPolicies] = useState(null);
  const [returnPolicies, setReturnPolicies] = useState(null);

  // States for loading and error handling
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Fetch Terms & Conditions
  const fetchTermsAndConditions = async () => {
    try {
      setLoading(true);
      const data = await getTermsAndConditions();
      setTermsAndConditions(data);
      setLoading(false);
    } catch (err) {
      console.error("Error fetching Terms & Conditions:", err);
      setError(err.message);
      setLoading(false);
    }
  };

  // Fetch Refund Policies
  const fetchRefundPolicies = async () => {
    try {
      setLoading(true);
      const data = await getRefundPolicies();
      setRefundPolicies(data);
      setLoading(false);
    } catch (err) {
      console.error("Error fetching Refund Policies:", err);
      setError(err.message);
      setLoading(false);
    }
  };

  // Fetch Return Policies
  const fetchReturnPolicies = async () => {
    try {
      setLoading(true);
      const data = await getReturnPolicies();
      setReturnPolicies(data);
      setLoading(false);
    } catch (err) {
      console.error("Error fetching Return Policies:", err);
      setError(err.message);
      setLoading(false);
    }
  };

  return (
    <PagesContext.Provider
      value={{
        termsAndConditions,
        refundPolicies,
        returnPolicies,
        loading,
        error,
        fetchTermsAndConditions,
        fetchRefundPolicies,
        fetchReturnPolicies,
      }}
    >
      {children}
    </PagesContext.Provider>
  );
};

// Custom Hook for consuming the context
const usePages = () => {
  return useContext(PagesContext);
};

export { PagesProvider, usePages };
