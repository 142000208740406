// src/Context/BlogsContext.js
import React, { createContext, useState, useEffect, useContext } from "react";
import {
  fetchAllBlogs,
  fetchSingleBlogBySlug,
} from "../apiServices/blogServices"; // Importing the service functions

// Creating the context for blogs
const BlogsContext = createContext();

// Creating a custom hook to use the BlogsContext
export const useBlogs = () => {
  return useContext(BlogsContext);
};

// Creating the provider component
export const BlogsProvider = ({ children }) => {
  const [blogs, setBlogs] = useState([]);
  const [singleBlog, setSingleBlog] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Function to fetch all blogs
  const getAllBlogs = async () => {
    setLoading(true);
    try {
      const data = await fetchAllBlogs();
      if (data?.data) {
        setBlogs(data.data);
      }
    } catch (err) {
      setError("Failed to fetch blogs.");
    } finally {
      setLoading(false);
    }
  };

  // Function to fetch a single blog by slug
  const getSingleBlog = async (slug) => {
    setLoading(true);
    try {
      const data = await fetchSingleBlogBySlug(slug);
      setSingleBlog(data);
    } catch (err) {
      setError("Failed to fetch blog.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllBlogs(); // Fetch all blogs on initial load
  }, []);

  return (
    <BlogsContext.Provider
      value={{
        blogs,
        singleBlog,
        getSingleBlog,
        loading,
        error,
      }}
    >
      {children}
    </BlogsContext.Provider>
  );
};
