import axios from "axios";
import i18n from "i18next";
import { API_ENDPOINTS } from "../ApiEndPoints";

export const languageServices = async (lng, setLoading, changeLanguage) => {
  setLoading(true);
  const switchLanguageURL = `${process.env.REACT_APP_API_URL}${API_ENDPOINTS.SETTINGS}?language=${lng}`;

  try {
    const response = await axios.get(switchLanguageURL, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.status !== 200) {
      throw new Error(`Failed to change language: ${response.status}`);
    }

    await i18n.changeLanguage(lng); // Change the language for static content
    changeLanguage(lng); // Update context state with the new language
  } catch (error) {
    console.error("Error changing language:", error);
  } finally {
    setLoading(false);
  }
};
