import axios from "axios";
import { API_ENDPOINTS } from "../ApiEndPoints";

const settingsURL = `${process.env.REACT_APP_API_URL}${API_ENDPOINTS.SETTINGS}`;

/**
 * Fetch settings from the API
 * @returns {Promise<Object>} - Resolves with the settings options or rejects with an error
 */
export const fetchSettings = async () => {
  const language = localStorage.getItem("language") || "de";
  try {
    const response = await axios.get(`${settingsURL}?language=${language}`);
    if (response.status === 200) {
      return response.data?.options || {};
    } else {
      console.error("Failed to fetch settings, status code:", response.status);
      return {};
    }
  } catch (error) {
    console.error("Error fetching settings:", error);
    throw error;
  }
};
