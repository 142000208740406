export const products = {
  medicine: [
    {
      id: 1,
      name: "Medicine 1",
      image:
        "https://images.unsplash.com/photo-1471864190281-a93a3070b6de?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
      id: 2,
      name: "Medicine 2",
      image:
        "https://images.unsplash.com/photo-1522335579687-9c718c5184d7?q=80&w=1471&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
      id: 3,
      name: "Medicine 3",
      image:
        "https://images.unsplash.com/photo-1523243319451-54b60322f948?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
  ],
  dryFruits: [
    {
      id: 4,
      name: "Almonds",
      image:
        "https://images.unsplash.com/photo-1579282940319-c483172dd0f2?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
      id: 5,
      name: "Cashews",
      image:
        "https://images.unsplash.com/photo-1608797178974-15b35a64ede9?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
      id: 6,
      name: "Dates",
      image:
        "https://images.unsplash.com/photo-1579282769050-7111761ffe0d?q=80&w=1374&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
  ],
  healthInstruments: [
    {
      id: 7,
      name: "Thermometer",
      image:
        "https://plus.unsplash.com/premium_photo-1723914178151-4a11fcbd968a?q=80&w=1416&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
      id: 8,
      name: "Blood Pressure Monitor",
      image:
        "https://media.istockphoto.com/id/852404126/photo/stethoscope-head-lying-on-medical-forms-closeup.webp?a=1&b=1&s=612x612&w=0&k=20&c=HiW5SB9LzebqutsbRW6BgKEcGHHTFHqT7QwO_i3sAHM=",
    },
    {
      id: 9,
      name: "Oximeter",
      image:
        "https://media.istockphoto.com/id/1310475310/photo/woman-looking-at-her-smart-watch-for-a-pulse-trace.webp?a=1&b=1&s=612x612&w=0&k=20&c=Wze83QT_62I9V3fENeCF5-Fwxr-kgLGap-uueBZ4taw=",
    },
  ],
};
