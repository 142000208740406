import React, { createContext, useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import {
  fetchCartFromServer,
  addToCartOnServer,
  removeFromCartOnServer,
} from "../apiServices/cartApi";
import { useLanguage } from "./LanguageContext";
import { useAuth } from "./AuthContext";
import { error } from "jquery";

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const { loginToken } = useAuth();
  const [cartItems, setCartItems] = useState(() => {
    const savedCartItems = localStorage.getItem("cartItems");
    return savedCartItems ? JSON.parse(savedCartItems) : [];
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isSynced, setSynced] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { language } = useLanguage();

  const fetchCart = async () => {
    if (loginToken) {
      // Fetch from server if logged in
      setIsLoading(true);
      try {
        const data = await fetchCartFromServer(language, loginToken);
        setCartItems(data.cartItems);
        localStorage.removeItem("cartItems"); // Clear local storage after sync
      } catch {
        enqueueSnackbar(
          "Kindly log in to your account to access your previously saved cart items.",
          {
            variant: "info",
            autoHideDuration: 1500,
          }
        );
      } finally {
        setIsLoading(false);
      }
    } else {
      // Fetch from local storage if logged out
      const cachedCartItems = localStorage.getItem("cartItems");
      setCartItems(cachedCartItems ? JSON.parse(cachedCartItems) : []);
    }
  };

  const syncCartWithServer = async () => {
    if (!loginToken) return;

    const localCartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
    for (const item of localCartItems) {
      try {
        await addToCartOnServer(
          loginToken,
          item.productId,
          item.variant_id,
          item.quantity
        );
        setSynced(true);
      } catch {
        console.log(error);
      }
    }
    localStorage.removeItem("cartItems");
    fetchCart();
  };

  useEffect(() => {
    if (loginToken && !isSynced) {
      syncCartWithServer();
    }
  }, [loginToken, isSynced]);

  const addToCart = async ({ product, variant_id, newQuantity }) => {
    // Sanitize the product object to include only necessary properties
    const sanitizedProduct = {
      name: product.name,
      price: product.price,
      description: product.description,
      image: product.image, // Ensure this is a simple object or URL
    };

    const newItem = {
      id: Date.now(), // Generate a unique ID for the cart item
      quantity: newQuantity,
      productId: product.productId,
      variant: product?.variant || [],
      variant_id: product?.variant?.id || null,
      product: sanitizedProduct,
    };

    const existingItem = cartItems.find(
      (item) =>
        item.productId === newItem.productId &&
        item.variant_id === newItem.variant_id
    );

    let updatedCartItems;
    let message = "Product added to cart!";

    if (existingItem) {
      updatedCartItems = cartItems.map((item) =>
        item === existingItem ? { ...item, quantity: newQuantity } : item
      );
      message = "Quantity updated.";
    } else {
      updatedCartItems = [...cartItems, newItem];
      message = "Product added to cart.";
    }

    setCartItems(updatedCartItems);

    if (loginToken) {
      try {
        await addToCartOnServer(
          loginToken,
          newItem.productId,
          newItem.variant_id,
          newQuantity
        );
        enqueueSnackbar(message, {
          variant: "success",
          autoHideDuration: 1500,
        });
      } catch (error) {
        console.error(error);
      }
    } else {
      try {
        localStorage.setItem("cartItems", JSON.stringify(updatedCartItems));
        enqueueSnackbar(message, {
          variant: "success",
          autoHideDuration: 1500,
        });
      } catch (error) {
        console.error("Error saving to localStorage:", error);
      }
    }
  };

  const removeFromCart = async (itemToRemove) => {
    const updatedCartItems = cartItems.filter(
      (item) => item.id !== itemToRemove.id
    );
    setCartItems(updatedCartItems);

    if (loginToken) {
      try {
        await removeFromCartOnServer(loginToken, itemToRemove.id);
        enqueueSnackbar("Product removed from cart.", {
          variant: "warning",
          autoHideDuration: 1500,
        });
      } catch {
        console.log(error);
      }
    } else {
      localStorage.setItem("cartItems", JSON.stringify(updatedCartItems));
    }
  };

  const clearCart = () => {
    setCartItems([]);
    if (loginToken) {
    } else {
      localStorage.removeItem("cartItems");
    }
  };

  return (
    <CartContext.Provider
      value={{
        cartItems,
        addToCart,
        removeFromCart,
        clearCart,
        fetchCart,
        isLoading,
        syncCartWithServer,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
