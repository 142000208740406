import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useSnackbar } from "notistack";
import { ClipLoader } from "react-spinners";

const ForgotPasswordPage = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [token, setToken] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const { email, token: tokenFromParams } = useParams(); // Extract token from URL path

  // If token is available in the URL path, set it
  useEffect(() => {
    if (tokenFromParams) {
      setToken(tokenFromParams);
    } else {
      enqueueSnackbar("Invalid or missing token.", { variant: "error" });
      navigate("/");
    }
  }, [tokenFromParams, enqueueSnackbar, navigate]);

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      enqueueSnackbar("Passwords do not match.", { variant: "error" });
      return;
    }
    setLoading(true);
    try {
      // Send the token inside the email object
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/reset-password`,
        {
          email: token,
          password: newPassword,
        }
      );
      if (response.status === 200) {
        enqueueSnackbar(" Password reset successfully!", {
          variant: "success",
        });
        navigate("/");
      } else {
        enqueueSnackbar("Failed to reset password.", { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar("An error occurred. Please try again.", {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mt-5">
      <h3 className="text-center mt-5 pt-5">{t("Reset-Password")}</h3>
      <form
        onSubmit={handlePasswordReset}
        className="col-md-6 mx-auto mb-5 pb-5"
      >
        <div className="form-group">
          <label htmlFor="newPassword">{t("New-Password")}</label>
          <input
            type="password"
            id="newPassword"
            className="form-control"
            placeholder={t("Enter-new-password")}
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
        </div>
        <div className="form-group mt-3">
          <label htmlFor="confirmPassword">{t("Confirm-Password")}</label>
          <input
            type="password"
            id="confirmPassword"
            className="form-control"
            placeholder={t("Confirm-new-password")}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </div>
        <button
          type="submit"
          className="btn btn-green-theme mt-4"
          disabled={loading}
        >
          {loading ? (
            <ClipLoader color={"#fff"} loading={loading} size={24} />
          ) : (
            t("Reset-Password")
          )}
        </button>
      </form>
    </div>
  );
};

export default ForgotPasswordPage;
