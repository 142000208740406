// src/api/cartApi.js
import axios from "axios";
import { API_ENDPOINTS } from "../ApiEndPoints";

const addToCartUrl = `${process.env.REACT_APP_API_URL}${API_ENDPOINTS.ADD_TO_CART}`;
const showCartUrl = `${process.env.REACT_APP_API_URL}${API_ENDPOINTS.SHOW_CART}`;
const removeFromCartUrl = `${process.env.REACT_APP_API_URL}${API_ENDPOINTS.REMOVE_FROM_CART}`;

// Fetch cart items from the server
export const fetchCartFromServer = async (language, loginToken) => {
  try {
    const response = await axios.get(showCartUrl, {
      params: { language },
      headers: {
        Authorization: `Bearer ${loginToken}`,
      },
    });
    // Axios automatically parses the response, so you can directly return the data
    return response.data;
  } catch (error) {
    console.error("Error fetching cart:", error.message);
    // Optionally, you can customize the error message or rethrow it
    throw error.response?.data?.message || "Failed to fetch cart";
  }
};

// Add item to the cart
export const addToCartOnServer = async (
  loginToken,
  productId,
  variantId,
  quantity
) => {
  try {
    await fetch(addToCartUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${loginToken}`,
      },
      body: JSON.stringify({ productId, variantId, quantity }),
    });
  } catch (error) {
    console.error("Failed to add item to cart:", error);
    throw error;
  }
};

// Remove item from the cart
export const removeFromCartOnServer = async (loginToken, cartId) => {
  try {
    await fetch(`${removeFromCartUrl}/${cartId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${loginToken}`,
      },
    });
  } catch (error) {
    console.error("Failed to remove item cart:", error);
    throw error;
  }
};
