import axios from "axios";
import { API_ENDPOINTS } from "../ApiEndPoints";

const addLikedProductUrl = `${process.env.REACT_APP_API_URL}${API_ENDPOINTS.LIKED_PRODUCT}`;
const showLikedProductUrl = `${process.env.REACT_APP_API_URL}${API_ENDPOINTS.SHOW_WISHLIST}`;
const removeLikedProductUrl = `${process.env.REACT_APP_API_URL}${API_ENDPOINTS.REMOVE_LIKED_PRODUCT}`;

// Fetch liked products from the server
export const fetchLikedProductsFromServer = async (language, loginToken) => {
  try {
    const response = await axios.get(showLikedProductUrl, {
      params: { language },
      headers: {
        Authorization: `Bearer ${loginToken}`,
      },
    });
    // Axios automatically parses the response, so you can directly return the data
    return response.data;
  } catch (error) {
    console.error("Error fetching liked products:", error.message);
    // Optionally, you can customize the error message or rethrow it
    throw error.response?.data?.message || "Failed to fetch liked products";
  }
};

export const addLikedProduct = async (productId, loginToken) => {
  const headers = {
    Authorization: `Bearer ${loginToken}`,
    "Content-Type": "application/json",
  };

  try {
    const response = await axios.post(
      addLikedProductUrl,
      { productId },
      { headers }
    );
    return response.status === 201;
  } catch (error) {
    console.error("Error adding product to wishlist", error.response || error);
    return false;
  }
};

export const removeLikedProduct = async (productId, loginToken) => {
  const headers = {
    Authorization: `Bearer ${loginToken}`,
    "Content-Type": "application/json",
  };
  const response = await axios.delete(`${removeLikedProductUrl}/${productId}`, {
    headers,
  });
  return response.status === 201;
};
