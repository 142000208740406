// src/services/userDataService.js
import axios from "axios";
import { API_ENDPOINTS } from "../ApiEndPoints";

const userDataUrl = `${process.env.REACT_APP_API_URL}${API_ENDPOINTS.USER_DATA}`;
const updateProfileUrl = `${process.env.REACT_APP_API_URL}${API_ENDPOINTS.UPDATE_PROFILE}`;
const attachmentUrl = `${process.env.REACT_APP_API_URL}${API_ENDPOINTS.ATTACHMENTS}`;

export const fetchUserData = async (loginToken) => {
  try {
    if (!loginToken) {
      throw new Error("No token found. User is not logged in.");
    }

    const response = await axios.get(userDataUrl, {
      headers: {
        Authorization: `Bearer ${loginToken}`,
      },
    });

    return response.data; // Return the API response data
  } catch (error) {
    console.error("Error fetching user data:", error.message); // Log detailed error
    throw new Error("Error fetching user data."); // Rethrow error for context to handle
  }
};

export const updateProfile = async (updatedData, loginToken) => {
  try {
    let avatarId = null;

    // Handle image upload if needed
    if (updatedData.profileImage) {
      const imageFormData = new FormData();
      imageFormData.append("attachment", updatedData.profileImage);

      const imageResponse = await axios.post(attachmentUrl, imageFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${loginToken}`,
        },
      });

      if (imageResponse.status === 201) {
        avatarId = imageResponse.data[0].id;
        updatedData.profileImageUrl = imageResponse.data[0].original;
      } else {
        throw new Error("Image upload failed.");
      }
    }

    const dataToSubmit = {
      firstname: updatedData.firstname,
      lastname: updatedData.lastname,
      email: updatedData.email,
      contact: updatedData.contact,
      avatar_id: avatarId || undefined,
    };

    const response = await axios.put(updateProfileUrl, dataToSubmit, {
      headers: {
        Authorization: `Bearer ${loginToken}`,
        "Content-Type": "application/json",
      },
    });

    return response.data;
  } catch (error) {
    throw new Error("Error updating profile.");
  }
};

export const uploadAttachment = async (file, loginToken) => {
  try {
    const formData = new FormData();
    formData.append("attachment", file);

    const response = await axios.post(attachmentUrl, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${loginToken}`,
      },
    });

    if (response.status === 201) {
      return response.data[0]; // Returning the uploaded file data (e.g., ID and URL)
    } else {
      throw new Error("Attachment upload failed.");
    }
  } catch (error) {
    throw new Error("Error uploading attachment.");
  }
};
