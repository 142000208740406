import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";

const MobileLinks = () => {
  const [showCategories, setShowCategories] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const products = {
    medicine: [
      {
        id: 1,
        name: "Medicine 1",
        image:
          "https://images.unsplash.com/photo-1471864190281-a93a3070b6de?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      },
      {
        id: 2,
        name: "Medicine 2",
        image:
          "https://images.unsplash.com/photo-1522335579687-9c718c5184d7?q=80&w=1471&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      },
      {
        id: 3,
        name: "Medicine 3",
        image:
          "https://images.unsplash.com/photo-1523243319451-54b60322f948?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      },
    ],
    dryFruits: [
      {
        id: 4,
        name: "Almonds",
        image:
          "https://images.unsplash.com/photo-1579282940319-c483172dd0f2?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      },
      {
        id: 5,
        name: "Cashews",
        image:
          "https://images.unsplash.com/photo-1608797178974-15b35a64ede9?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      },
      {
        id: 6,
        name: "Dates",
        image:
          "https://images.unsplash.com/photo-1579282769050-7111761ffe0d?q=80&w=1374&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      },
    ],
    healthInstruments: [
      {
        id: 7,
        name: "Thermometer",
        image:
          "https://plus.unsplash.com/premium_photo-1723914178151-4a11fcbd968a?q=80&w=1416&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      },
      {
        id: 8,
        name: "Blood Pressure Monitor",
        image:
          "https://media.istockphoto.com/id/852404126/photo/stethoscope-head-lying-on-medical-forms-closeup.webp?a=1&b=1&s=612x612&w=0&k=20&c=HiW5SB9LzebqutsbRW6BgKEcGHHTFHqT7QwO_i3sAHM=",
      },
      {
        id: 9,
        name: "Oximeter",
        image:
          "https://media.istockphoto.com/id/1310475310/photo/woman-looking-at-her-smart-watch-for-a-pulse-trace.webp?a=1&b=1&s=612x612&w=0&k=20&c=Wze83QT_62I9V3fENeCF5-Fwxr-kgLGap-uueBZ4taw=",
      },
    ],
  };

  const toggleCategories = () => {
    setShowCategories(!showCategories);
    setSelectedCategory(null); // Reset selected category when toggling
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(selectedCategory === category ? null : category);
  };

  return (
    <div className="mobile-links-container">
      <div className="mobile-links d-flex d-lg-none flex-column">
        <span className="our-products-link" onClick={toggleCategories}>
          Our Products
          <span className="ps-2 pt-1">
            {showCategories ? (
              <FontAwesomeIcon icon={faAngleUp} />
            ) : (
              <FontAwesomeIcon icon={faAngleDown} />
            )}
          </span>
        </span>

        {showCategories && (
          <div className="categories">
            <span
              className="category-link"
              onClick={() => handleCategoryClick("medicine")}
            >
              - Medicine
            </span>
            {selectedCategory === "medicine" && (
              <div className="mobile-slider">
                <div className="slider-container">
                  {products.medicine.map((product) => (
                    <div key={product.id} className="slider-item">
                      <img src={product.image} alt={product.name} />
                      <h5>{product.name}</h5>
                    </div>
                  ))}
                </div>
              </div>
            )}

            <span
              className="category-link"
              onClick={() => handleCategoryClick("dryFruits")}
            >
              - Dry Fruits
            </span>
            {selectedCategory === "dryFruits" && (
              <div className="mobile-slider">
                <div className="slider-container">
                  {products.dryFruits.map((product) => (
                    <div key={product.id} className="slider-item">
                      <img src={product.image} alt={product.name} />
                      <h5>{product.name}</h5>
                    </div>
                  ))}
                </div>
              </div>
            )}

            <span
              className="category-link"
              onClick={() => handleCategoryClick("healthInstruments")}
            >
              - Health Instruments
            </span>
            {selectedCategory === "healthInstruments" && (
              <div className="mobile-slider">
                <div className="slider-container">
                  {products.healthInstruments.map((product) => (
                    <div key={product.id} className="slider-item">
                      <img src={product.image} alt={product.name} />
                      <h5>{product.name}</h5>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      {/* Styles */}
      <style jsx>{`
        .mobile-links-container {
          display: flex;
          flex-direction: column;
          background-color: #f9f9f9;
        }
        .our-products-link {
          font-size: 16px;
          cursor: pointer;
          color: #212529;
        }
        .categories {
          margin-top: 10px;
          display: flex;
          flex-direction: column;
          gap: 10px;
        }
        .category-link {
          font-size: 14px;
          color: #555;
          cursor: pointer;
        }
        .category-link:hover {
          color: #000;
        }
        .mobile-slider {
          margin: 10px 0;
        }
        .slider-container {
          display: flex;
          gap: 15px;
          overflow-x: auto;
        }
        /* Style the scrollbar */
        .slider-container::-webkit-scrollbar {
          width: 4px; /* Thinner width of the scrollbar */
          height: 3px;
        }

        /* Style the scrollbar track (the background of the scrollbar) */
        .slider-container::-webkit-scrollbar-track {
          background: #f1f1f1; /* Light background color */
          border-radius: 10px; /* Rounded corners for the track */
        }

        /* Style the scrollbar thumb (the draggable part) */
        .slider-container::-webkit-scrollbar-thumb {
          background: #888; /* Darker color for the thumb */
          border-radius: 10px; /* Rounded corners for the thumb */
        }

        /* Style the scrollbar thumb when hovered */
        .slider-container::-webkit-scrollbar-thumb:hover {
          background: #555; /* Change color on hover */
        }

        .slider-item {
          flex: 0 0 auto;
          text-align: center;
          width: 250px;
        }
        .slider-item img {
          width: 100%;
          height: 120px;
          object-fit: cover;
          border-radius: 8px;
        }
        .slider-item h5 {
          margin-top: 5px;
          font-size: 14px;
          color: #212529;
        }
      `}</style>
    </div>
  );
};

export default MobileLinks;
