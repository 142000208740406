import React from "react";
import "./FooterPages.css";
import ProductCard from "../../products/ProductCards";
import { Link } from "react-router-dom";

const HappyCustomers = () => {
  const testimonials = [
    {
      name: "John Miller",
      feedback: "Amazing products and fast delivery! Very satisfied.",
      product: "Medicines",
      country: "Germany",
    },
    {
      name: "Clara Smith",
      feedback: "The quality of the dry fruits is exceptional!",
      product: "Dry Fruits",
      country: "Germany",
    },
    {
      name: "Peter Fischer",
      feedback: "The health instruments here are of the highest quality.",
      product: "Health Instruments",
      country: "Germany",
    },
  ];

  return (
    <div className="happy-customers">
      {/* Hero Section */}
      <div className="container-fluid faq-heading">
        <div className="row">
          <h1>Our Happy Customers</h1>
          <p className="lead">Hear what our customers have to say about us!</p>
        </div>
      </div>

      <section className="testimonials-section py-5">
        <div className="container">
          <h2 className="text-center mb-5">Customer Testimonials</h2>
          <div className="row">
            {testimonials.map((testimonial, index) => (
              <div className="col-md-4 d-flex mb-4" key={index}>
                <div className="card mb-4 shadow-lg border-0 rounded-3 hover-effect w-100">
                  <div className="card-body d-flex flex-column">
                    <div className="d-flex align-items-center mb-4">
                      {/* User Image */}
                      <img
                        src={`https://i.pravatar.cc/150?img=${index + 1}`} // Placeholder image
                        alt={testimonial.name}
                        className="rounded-circle me-3"
                        style={{ width: "60px", height: "60px" }}
                      />
                      <div>
                        <h5 className="card-title mb-1 font-weight-bold">
                          {testimonial.name}
                        </h5>
                        <h6 className="card-subtitle mb-2 text-muted">
                          {testimonial.country}
                        </h6>
                      </div>
                    </div>

                    {/* Star Ratings */}
                    <div className="mb-3">
                      {index === 0 ? (
                        // 5 Golden Stars for first testimonial
                        <div className="text-warning">
                          <span>★</span>
                          <span>★</span>
                          <span>★</span>
                          <span>★</span>
                          <span>★</span>
                        </div>
                      ) : (
                        // 4 Golden Stars and 1 Muted Star for second and others
                        <div className="text-warning">
                          <span>★</span>
                          <span>★</span>
                          <span>★</span>
                          <span>★</span>
                          <span className="text-muted">★</span>
                        </div>
                      )}
                    </div>

                    <p className="card-text font-italic mb-3">
                      "{testimonial.feedback}"
                    </p>
                    <p>
                      <strong>Product:</strong> {testimonial.product}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Featured Products Section */}
      <ProductCard />

      {/* Call-to-Action Section */}
      <section className="cta-section text-center py-5">
        <h2>Share Your Feedback With Us!</h2>
        <p>Your feedback helps us improve our services. Leave us a review!</p>
        <Link to="/contact-us">
          <button className="btn btn-primary btn-lg">Submit a Review</button>
        </Link>
      </section>
    </div>
  );
};

export default HappyCustomers;
