export const API_ENDPOINTS = {
  BANNER: "/userbanner",
  PAGES: "/userpages",
  SETTINGS: "/settings",
  LOGIN: "/login",
  PAGES: "/userpages",
  ALL_BLOGS: "/blogList",
  SINGLE_BLOG: "/userblogs",
  REGISTER: "/userregister",
  VERIFY_OTP: "/verify-otp",
  ATTACHMENTS: "/attachments",
  VERYFY_OLDPASSWORD: "/user/change-password",
  SEND_OTP: "/user/changePassword-verifyOtp",
  SET_NEW_PASSWORD: "/user/newPassword",
  // PRODUCTS: '/products?searchJoin=and&limit=20&page=1&orderBy=createdAt&sortedBy=desc&language=',
  PRODUCTS:
    "/webproducts?searchJoin=and&limit=20&page=1&orderBy=createdAt&sortedBy=desc&",
  SHOW_CART: "/show-cart",
  ADD_TO_CART: "/add-to-cart",
  REMOVE_FROM_CART: "/delete-from-cart",
  LIKED_PRODUCT: "/add-to-wishlist",
  SHOW_WISHLIST: "/show-wishlist",
  REMOVE_LIKED_PRODUCT: "/delete-from-wishlist",
  ADDRESS: "/address",
  COUNTRY_CITY: "/usercity",
  SHOW_ADDRESS: "/address-list",
  DELETE_ADDRESS: "/address",
  UPDATE_ADDRESS: "/address",
  USER_DATA: "/user/profile",
  UPDATE_PROFILE: "/user/update-profile",
  PLACE_ORDER: "/place-order",
  SHOW_MY_ORDER: "/pending-order-list",
  SHOW_ORDER_HISTORY: "/complete-order-list",
  CANCLE_ORDER: "/cancel-order",
  REPEAT_ORDER: "/reorder",
  CONTACT_US: "/contact",
  FAQS: "/listFaqs",
  SHOW_REVIEWS: "/listreviews",
  SUBMIT_REVIEW: "/reviews",
  UPDATE_REVIEW: "/reviews",
  ASK_A_QUESTION: "/questions",
  GENARATE_INVOICE: "/download-invoice-url",
};
