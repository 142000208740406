import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import "./NavbarComponents.css";
import { Link } from "react-router-dom";
import { menuData } from "./menuData.js";

const NavbarWithDropdown = ({ isHomePage }) => {
  const [activeMenu, setActiveMenu] = useState(null); // Tracks active top-level category
  const [activeLink, setActiveLink] = useState(null); // Tracks active first-level link
  const [activeSubLink, setActiveSubLink] = useState(null); // Tracks active second-level link

  const handleMenuClick = (menu) => {
    if (activeMenu === menu) {
      setActiveMenu(null); // Toggle off
    } else {
      setActiveMenu(menu); // Set the clicked menu as active
      setActiveLink(null); // Reset lower levels
      setActiveSubLink(null);
    }
  };

  const handleLinkHover = (link) => {
    setActiveLink(link);
    setActiveSubLink(null); // Reset lower levels
  };

  const handleSubLinkHover = (subLink) => {
    setActiveSubLink(subLink);
  };

  const handleMouseLeave = () => {
    // Clear all active states when mouse leaves dropdown
    setActiveMenu(null);
    setActiveLink(null);
    setActiveSubLink(null);
  };
  return (
    <>
      {isHomePage && (
        <div className="navbar-container">
          <div className="container d-none d-lg-flex justify-content-center">
            {menuData.map((menu) => (
              <button
                key={menu.category}
                className="btn btn-custom mx-3"
                onClick={() => handleMenuClick(menu.category)}
                onMouseEnter={() => setActiveMenu(menu.category)}
              >
                {menu.category.charAt(0).toUpperCase() + menu.category.slice(1)}
              </button>
            ))}
          </div>

          {/* Mega Dropdown */}
          {activeMenu && (
            <div
              className="mega-dropdown row"
              onMouseLeave={handleMouseLeave} // Close dropdown on mouse leave
            >
              <div className="dropdown-links col-12">
                {/* First Level */}
                <div className="first-level">
                  {menuData
                    .find((menu) => menu.category === activeMenu)
                    ?.links.map((link, index) => (
                      <div
                        key={index}
                        className={`dropdown-item ${
                          activeLink === link ? "active" : ""
                        }`}
                        onMouseEnter={() => handleLinkHover(link)}
                        onClick={() => handleLinkHover(link)}
                      >
                        {link.name}
                        {link.subLinks.length > 0 && (
                          <FontAwesomeIcon
                            icon={faArrowRight}
                            className="right-arrow"
                          />
                        )}
                      </div>
                    ))}
                </div>

                {/* Second Level */}
                {activeLink && (
                  <div className="second-level">
                    {activeLink.subLinks.map((subLink, index) => (
                      <div
                        key={index}
                        className={`dropdown-item ${
                          activeSubLink === subLink ? "active" : ""
                        }`}
                        onMouseEnter={() => handleSubLinkHover(subLink)}
                        onClick={() => handleSubLinkHover(subLink)}
                      >
                        {subLink.name}
                        {subLink.subLinks.length > 0 && (
                          <FontAwesomeIcon
                            icon={faArrowRight}
                            className="right-arrow"
                          />
                        )}
                      </div>
                    ))}
                  </div>
                )}

                {/* Third Level */}
                {activeSubLink && (
                  <div className="third-level">
                    {activeSubLink.subLinks.map((subSubLink, index) => (
                      <div key={index} className="dropdown-item">
                        {subSubLink.name}
                      </div>
                    ))}
                  </div>
                )}
              </div>

              {/* Product Image */}
              <div className="p-4 dropdown-product col-12 d-flex overflow-auto">
                {menuData
                  .filter((menu) => menu.category === activeMenu)
                  .map(
                    (menu) =>
                      menu.products.length > 0 &&
                      menu.products.map((product) => (
                        <>
                          <div
                            key={product.id}
                            className="product-item d-flex flex-column align-items-center px-4"
                          >
                            <img src={product.image} alt={product.name} />
                            <Link to="#">
                              {/* <Link to={`/products/${"Add productId here!"}`}> */}
                              <h6>{product.name}</h6>
                            </Link>
                          </div>
                        </>
                      ))
                  )}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default NavbarWithDropdown;
