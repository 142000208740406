import React, { createContext, useState } from "react";
import {
  showMyOrders,
  showOrderHistory,
  repeatOrder,
  cancelOrder as fetchOrderCancel,
  genarateInvioce,
} from "../apiServices/OrderService";

export const MyOrderContext = createContext();

export const MyOrderProvider = ({ children }) => {
  const [isReview, setIsReview] = useState(false);
  const [orders, setOrders] = useState([]);
  const [oldOrders, setOldOrders] = useState([]);
  const [repeatOrders, setRepeatOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Function to fetch orders
  const fetchMyOrders = async (language, loginToken) => {
    setLoading(true);
    setError(null);

    try {
      const data = await showMyOrders(language, loginToken);
      setOrders(data.orders);
    } catch (err) {
      console.error("Error fetching orders:", err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Function to fetch orders
  const orderHistory = async (language, loginToken) => {
    setLoading(true);
    setError(null);

    try {
      const data = await showOrderHistory(language, loginToken);
      setOldOrders(data.orders);
      setIsReview(data.orders);
    } catch (err) {
      console.error("Error fetching orders:", err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Function to cancel an order
  const cancelOrder = async (orderId, loginToken) => {
    setLoading(true);
    setError(null);

    try {
      await fetchOrderCancel(orderId, loginToken);
      // Update orders state by removing the canceled order
      setOrders((prevOrders) =>
        prevOrders.filter((order) => order.id !== orderId)
      );
    } catch (err) {
      console.error("Error canceling order:", err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Function to cancel an order
  const handleRepeatOrder = async (orderId, loginToken) => {
    setLoading(true);
    setError(null);

    try {
      await repeatOrder(orderId, loginToken);
      // Update orders state by removing the canceled order
      setRepeatOrders((prevOrders) =>
        prevOrders.filter((order) => order.id !== orderId)
      );
    } catch (err) {
      console.error("Error Repeating order:", err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleGenarateInvoice = async (orderId, loginToken) => {
    setLoading(true);
    setError(null);

    try {
      const invoiceUrl = await genarateInvioce(orderId, loginToken);

      if (invoiceUrl) {
        // Open the generated invoice PDF in a new tab
        window.open(invoiceUrl, "_blank");
      }
    } catch (err) {
      console.error("Error generating invoice", err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <MyOrderContext.Provider
      value={{
        orders,
        loading,
        error,
        fetchMyOrders,
        orderHistory,
        handleRepeatOrder,
        oldOrders,
        repeatOrders,
        cancelOrder,
        handleGenarateInvoice,
      }}
    >
      {children}
    </MyOrderContext.Provider>
  );
};

export default MyOrderProvider;
