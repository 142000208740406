import axios from "axios";
import { API_ENDPOINTS } from "../ApiEndPoints";

const veryfyOldPasswordUrl = `${process.env.REACT_APP_API_URL}${API_ENDPOINTS.VERYFY_OLDPASSWORD}`;
const sendOtpUrl = `${process.env.REACT_APP_API_URL}${API_ENDPOINTS.SEND_OTP}`;
const setNewPasswordUrl = `${process.env.REACT_APP_API_URL}${API_ENDPOINTS.SET_NEW_PASSWORD}`;

export const verifyCurrentPassword = async (currentPassword, loginToken) => {
  const response = await axios.post(
    veryfyOldPasswordUrl,
    { oldPassword: currentPassword },
    {
      headers: { Authorization: `Bearer ${loginToken}` },
    }
  );
  return response.data;
};

export const verifyOtp = async (otp, loginToken) => {
  const response = await axios.post(
    sendOtpUrl,
    { otp },
    {
      headers: { Authorization: `Bearer ${loginToken}` },
    }
  );
  return response.data;
};

export const changePassword = async (newPassword, loginToken) => {
  const response = await axios.post(
    setNewPasswordUrl,
    { newPassword },
    {
      headers: { Authorization: `Bearer ${loginToken}` },
    }
  );
  return response.data;
};
