import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function Footer() {
  const { t } = useTranslation();

  return (
    <div className="pg-footer bg-light">
      <footer className="footer">
        <svg
          className="footer-wave-svg"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 100"
          preserveAspectRatio="none"
        >
          <path
            className="footer-wave-path"
            d="M851.8,100c125,0,288.3-45,348.2-64V0H0v44c3.7-1,7.3-1.9,11-2.9C80.7,22,151.7,10.8,223.5,6.3C276.7,2.9,330,4,383,9.8 c52.2,5.7,103.3,16.2,153.4,32.8C623.9,71.3,726.8,100,851.8,100z"
          ></path>
        </svg>
        <div className="text-center m-0 pt-3 px-2">
          <h5 className="fw-bold">
            {t("@All-prices-including-taxes-and-fees")}
          </h5>
          <hr />
        </div>
        <div className="container pt-0">
          <div className="row">
            {/* Company Column */}
            <div className="col-12 col-md-3">
              <div className="footer-menu">
                <h2 className="footer-menu-name">{t("Company")}</h2>
                <ul id="menu-company" className="footer-menu-list">
                  <li className="footer-links-color">
                    <Link to="/about-us">{t("About-Us")}</Link>
                  </li>
                  <li className="footer-links-color">
                    <Link to="/contact-us">{t("Contact-Us")}</Link>
                  </li>
                  <li className="footer-links-color">
                    <Link to="/faqs">{t("FAQS")}</Link>
                  </li>
                </ul>
              </div>
            </div>

            {/* Legal Column */}
            <div className="col-12 col-md-3">
              <div className="footer-menu">
                <h2 className="footer-menu-name">Legal</h2>
                <ul id="menu-legal" className="footer-menu-list">
                  <li className="footer-links-color">
                    <Link to="/terms&condition">{t("Terms&Conditions")}</Link>
                  </li>
                  <li className="footer-links-color">
                    <Link to="/refund-policies">{t("Refund-Policies")}</Link>
                  </li>
                  <li className="footer-links-color">
                    <Link to="/return-policies">{t("Return-Policies")}</Link>
                  </li>
                </ul>
              </div>
            </div>

            {/* Quick Links Column */}
            <div className="col-12 col-md-3">
              <div className="footer-menu">
                <h2 className="footer-menu-name">{t("Quick-Links")}</h2>
                <ul id="menu-quick-links" className="footer-menu-list">
                  <li className="footer-links-color">
                    <Link rel="noopener noreferrer" to="/ask-a-questions">
                      {t("Support-Center")}
                    </Link>
                  </li>
                  <li className="footer-links-color">
                    <Link
                      to="/user-profile"
                      state={{ activeComponent: "my-orders" }}
                      rel="noopener noreferrer"
                    >
                      {t("Track-Orders")}
                    </Link>
                  </li>

                  <li className="footer-links-color">
                    <Link to="/blog">{t("Blog")}</Link>
                  </li>
                  <li className="footer-links-color">
                    <Link to="/happy-customers">{t("Happy-Customers")}</Link>
                  </li>
                </ul>
              </div>
            </div>

            {/* Call to Action Column */}
            <div className="col-12 col-md-3">
              <div className="footer-call-to-action">
                <h2 className="footer-call-to-action-title">
                  {t("Lets-Chat")}
                </h2>
                <p className="footer-call-to-action-description">
                  {t("Have-a-support-question?")}
                </p>
                <Link
                  className="footer-call-to-action-button"
                  to="/ask-a-questions"
                >
                  {t("Ask-a-question")}
                </Link>
              </div>
              <div className="footer-call-to-action">
                <h2 className="footer-call-to-action-title">
                  {t("You-Call-Us")}
                </h2>
                <p className="footer-call-to-action-link-wrapper">
                  <Link
                    className="footer-call-to-action-link"
                    to="tel:0124-64XXXX"
                    target="_self"
                  >
                    0124-64XXXX
                  </Link>
                </p>
              </div>
            </div>
          </div>

          <div className="row pt-3">
            {/* Social Links */}
            <div className="col-12 d-flex justify-content-center gap-3">
              <Link
                className="footer-social-link linkedin text-white"
                to="#"
                target="_blank"
              >
                <FontAwesomeIcon icon={["fab", "linkedin-in"]} />
              </Link>
              <Link
                className="footer-social-link twitter text-white"
                to="#"
                target="_blank"
              >
                <FontAwesomeIcon icon={["fab", "twitter"]} size="2x" />
              </Link>
              <Link
                className="footer-social-link youtube text-white"
                to="#"
                target="_blank"
              >
                <FontAwesomeIcon icon={["fab", "youtube"]} />
              </Link>
              <Link
                className="footer-social-link github text-white"
                to="#"
                target="_blank"
              >
                <FontAwesomeIcon icon={["fab", "github"]} size="2x" />
              </Link>
            </div>
          </div>

          <div className="footer-copyright pt-4">
            <div className="footer-copyright-wrapper">
              <p className="footer-copyright-text fw-bold fs-6">
                {t("Copyright")}
                <Link
                  className="footer-copyright-link ps-1"
                  to="https://alltrients.de"
                  target="_self"
                >
                  alltrients.de
                </Link>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
