import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Flag from "react-world-flags";
import ClipLoader from "react-spinners/ClipLoader";
import { Link } from "react-router-dom";
import { languageServices } from "../apiServices/langauageServices";
import { useLanguage } from "../Context/LanguageContext";

const LanguageSwitcher = ({ handleCloseDrawer }) => {
  const { i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { changeLanguage, language } = useLanguage();

  const handleChangeLanguage = async (lng, e) => {
    e.preventDefault();
    await languageServices(lng, setLoading, changeLanguage);
    handleCloseDrawer();
  };

  return (
    <div>
      {loading && (
        <div className="spinner-overlay">
          <ClipLoader
            className=""
            color="#ffffff"
            loading={loading}
            size={60}
          />
        </div>
      )}

      <ul className="navbar-nav mb-2 mb-lg-0">
        <li className="nav-item dropdown py-0">
          <Link
            to="#"
            className="nav-link dropdown-toggle pt-0 pt-md-2"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <Flag
              code={language === "en" ? "GB" : "DE"}
              style={{ width: "30px", marginRight: "8px" }}
            />
          </Link>
          <ul
            className="dropdown-menu dropdown-menu-end position-absolute rounded-0 mt-md-4 me-md-1"
            aria-labelledby="languageDropdown"
          >
            <li>
              <Link
                to="#"
                className="dropdown-item"
                onClick={(e) => handleChangeLanguage("en", e)}
              >
                <Flag code="GB" style={{ width: "20px", marginRight: "8px" }} />{" "}
                English
              </Link>
            </li>
            <li>
              <Link
                to="#"
                className="dropdown-item"
                onClick={(e) => handleChangeLanguage("de", e)}
              >
                <Flag code="DE" style={{ width: "20px", marginRight: "8px" }} />{" "}
                German
              </Link>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default LanguageSwitcher;
