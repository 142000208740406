import { API_ENDPOINTS } from "../ApiEndPoints";

const blogsUrl = `${process.env.REACT_APP_API_URL}${API_ENDPOINTS.ALL_BLOGS}`;
const singleBlogUrl = `${process.env.REACT_APP_API_URL}${API_ENDPOINTS.SINGLE_BLOG}`;

// Function to fetch all blogs
export const fetchAllBlogs = async () => {
  try {
    const response = await fetch(blogsUrl);
    if (!response.ok) {
      throw new Error("Error fetching all blogs.");
    }
    const data = await response.json();
    return data; // Return the fetched blogs data
  } catch (error) {
    console.error("Error fetching all blogs:", error);
    throw error; // Propagate the error to be handled elsewhere
  }
};

// Function to fetch a single blog by slug
export const fetchSingleBlogBySlug = async (slug) => {
  try {
    const response = await fetch(`${singleBlogUrl}/${slug}`);
    if (!response.ok) {
      throw new Error("Error fetching the single blog.");
    }
    const data = await response.json();
    return data; // Return the single blog data
  } catch (error) {
    console.error(`Error fetching blog with slug ${slug}:`, error);
    throw error; // Propagate the error to be handled elsewhere
  }
};
