import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { usePages } from "../../Context/pagesContext";

const RefundPolicies = () => {
  const { t } = useTranslation();
  const { refundPolicies, loading, error, fetchRefundPolicies } = usePages(); // Assuming `refundPolicies` is available in the context
  const [modifiedHtml, setModifiedHtml] = useState("");

  useEffect(() => {
    if (refundPolicies?.description) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(
        refundPolicies.description,
        "text/html"
      );

      // Add classes to <h5>
      doc.querySelectorAll("h5").forEach((el) => {
        el.classList.add("text-primary", "mb-4", "fw-bold");
      });

      // Add classes to <p> and append <hr /> after each <p>
      doc.querySelectorAll("p").forEach((el) => {
        el.classList.add("fs-6", "text-muted");

        // Create <hr> and append it after <p>
        const hr = document.createElement("hr");
        el.parentNode.insertBefore(hr, el.nextSibling);
      });

      // Add classes to <strong>
      doc.querySelectorAll("strong").forEach((el) => {
        el.classList.add("ms-1");
      });

      // Serialize the modified HTML
      setModifiedHtml(doc.body.innerHTML);
    }
  }, [refundPolicies]);

  // Fetch all data on mount
  useEffect(() => {
    fetchRefundPolicies();
  }, []);

  if (loading) {
    return <div className="loading">{t("Loading...")}</div>;
  }

  if (error) {
    return <div className="error">{t("Error loading refund policies.")}</div>;
  }

  return (
    <>
      <div className="container-fluid faq-heading">
        <div className="row">
          <h1>{t("Refund Policies")}</h1>
          <p>
            {t(
              "Please review our refund policies carefully before requesting a refund."
            )}
          </p>
        </div>
      </div>
      <div className="container my-5">
        <div className="row">
          <div className="col-12">
            <div className="card shadow border-0 rounded-0 overflow-hidden">
              <div className="card-body bg-light">
                {/* Render the refundPolicies content dynamically */}
                <div
                  dangerouslySetInnerHTML={{
                    __html: modifiedHtml || "",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RefundPolicies;
