import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  Box,
  Typography,
  CircularProgress,
} from "@mui/material";

const OtpVerificationModal = ({
  open,
  onClose,
  handleOtpSubmit,
  otp,
  setOtp,
  errorMessage,
  setErrorMessage,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Reset OTP state and error message when modal opens
  useEffect(() => {
    if (open) {
      setOtp(new Array(6).fill("")); // Clear OTP state when modal opens
      setErrorMessage(""); // Reset error message
    }
  }, [open]);

  // Handle individual input change
  const handleChange = (element, index) => {
    if (/^\d$/.test(element.value) || element.value === "") {
      const newOtp = [...otp];
      newOtp[index] = element.value;
      setOtp(newOtp);

      // Automatically focus on the next input if available
      if (element.value !== "" && index < 5) {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
    }
  };

  const handleSubmit = async () => {
    const enteredOtp = otp.join("");
    if (enteredOtp.length === 6) {
      setIsSubmitting(true);
      try {
        // Call the handleOtpSubmit function passed from parent
        await handleOtpSubmit(enteredOtp);
        setErrorMessage(""); // Clear any previous error
      } catch (error) {
        setErrorMessage("Enter valid code!"); // Set error message if OTP is invalid
        setOtp(new Array(6).fill("")); // Clear OTP fields
      } finally {
        setIsSubmitting(false);
      }
    } else {
      setErrorMessage("Please enter all 6 digits of the OTP.");
    }
  };

  const handleBackspace = (e, index) => {
    if (e.key === "Backspace" && !otp[index] && index > 0) {
      document.getElementById(`otp-input-${index - 1}`).focus();
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <Box padding={3}>
        <DialogTitle align="center">Verify Your Email</DialogTitle>
        <Typography align="center" marginBottom={2}>
          Enter the Verification code we sent to your email.
        </Typography>
        <Box display="flex" justifyContent="center" gap={1} marginBottom={3}>
          {otp.map((digit, index) => (
            <input
              key={index}
              id={`otp-input-${index}`}
              type="text"
              maxLength={1}
              value={digit}
              onChange={(e) => handleChange(e.target, index)}
              onKeyDown={(e) => handleBackspace(e, index)}
              style={{
                width: "3rem",
                height: "3rem",
                fontSize: "1.5rem",
                textAlign: "center",
                border: "1px solid #ccc",
                borderRadius: "8px",
                outline: "none",
                ...(errorMessage && { borderColor: "red" }), // Red border for error
              }}
            />
          ))}
        </Box>

        {/* Error message */}
        {errorMessage && (
          <Typography color="red" align="center" marginBottom={2}>
            {errorMessage}
          </Typography>
        )}

        <DialogActions>
          <Button onClick={onClose} variant="outlined" fullWidth>
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            fullWidth
            disabled={isSubmitting}
            startIcon={isSubmitting ? <CircularProgress size={24} /> : null}
          >
            {isSubmitting ? "Verifying..." : "Verify"}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default OtpVerificationModal;
