import { API_ENDPOINTS } from "../ApiEndPoints";

const placeOrderUrl = `${process.env.REACT_APP_API_URL}${API_ENDPOINTS.PLACE_ORDER}`;
const showMyOrdersUrl = `${process.env.REACT_APP_API_URL}${API_ENDPOINTS.SHOW_MY_ORDER}`;
const showOrderHistoryUrl = `${process.env.REACT_APP_API_URL}${API_ENDPOINTS.SHOW_ORDER_HISTORY}`;
const cancleMyOrderUrl = `${process.env.REACT_APP_API_URL}${API_ENDPOINTS.CANCLE_ORDER}`;
const repeatOrderUrl = `${process.env.REACT_APP_API_URL}${API_ENDPOINTS.REPEAT_ORDER}`;
const genarateInvioceUrl = `${process.env.REACT_APP_API_URL}${API_ENDPOINTS.GENARATE_INVOICE}`;

// Function to place an order
export const placeOrder = async (
  { shippingMethod, comment, addressId, payment },
  loginToken
) => {
  const orderData = {
    shippingMethod,
    comment,
    addressId,
    payment,
  };

  try {
    const response = await fetch(placeOrderUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${loginToken}`,
      },
      body: JSON.stringify(orderData),
    });

    if (!response.ok) {
      throw new Error(`Failed to place order: ${response.statusText}`);
    }

    return await response.json();
  } catch (error) {
    console.error("Error placing order:", error);
    throw error;
  }
};

// Function to show my orders
export const showMyOrders = async (language, loginToken) => {
  if (!loginToken) {
    throw new Error("Please log in to view your orders.");
  }

  try {
    const url = new URL(showMyOrdersUrl);
    url.searchParams.append("language", language);

    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${loginToken}`,
      },
    });

    if (!response.ok) {
      if (response.status === 401 || response.status === 403) {
        throw new Error("Session expired. Please log in again.");
      }
      throw new Error(`Failed to fetch orders: ${response.statusText}`);
    }

    return await response.json();
  } catch (error) {
    console.error("Error fetching orders:", error);
    throw error;
  }
};

// Function to show my orders
export const showOrderHistory = async (language, loginToken) => {
  if (!loginToken) {
    throw new Error("Please log in to view your orders.");
  }
  try {
    // Construct the URL with query parameters
    const url = new URL(showOrderHistoryUrl);
    if (language) {
      url.searchParams.append("language", language);
    }

    // Fetch the data with the constructed URL
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${loginToken}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch orders: ${response.statusText}`);
    }

    return await response.json();
  } catch (error) {
    console.error("Error fetching orders:", error);
    throw error;
  }
};

// Function to cancel an order
export const cancelOrder = async (orderId, loginToken) => {
  try {
    const response = await fetch(`${cancleMyOrderUrl}/${orderId}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${loginToken}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Failed to cancel order: ${response.statusText}`);
    }

    return await response.json();
  } catch (error) {
    console.error("Error canceling order:", error);
    throw error;
  }
};

// Function to repeat a order
export const repeatOrder = async (orderId, loginToken) => {
  try {
    const response = await fetch(`${repeatOrderUrl}/${orderId}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loginToken}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Failed to Re-order the item: ${response.statusText}`);
    }

    return await response.json();
  } catch (error) {
    console.error("Error Repeating Order:", error);
    throw error;
  }
};

// Function to genarate an invoice
export const genarateInvioce = async (orderId, loginToken) => {
  console.log(orderId);
  try {
    const url = new URL(genarateInvioceUrl);

    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loginToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        order_id: orderId,
      }),
    });

    if (!response.ok) {
      if (response.status === 401 || response.status === 403) {
        throw new Error("Session expired. Please log in again.");
      }
      throw new Error(`Failed to generate invoice: ${response.statusText}`);
    }

    const result = await response.json();

    // Assuming the URL is in the response object
    return result.url; // return the URL of the generated invoice
  } catch (error) {
    console.error("Error generating invoice:", error);
    throw error;
  }
};
